import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useChatMessages } from 'features/firebase/hooks'
import { Message, MessageInputProp } from 'features/firebase/types'
import { selectProfile } from 'features/user/selectors'
import '../index.scss'

export default function MessageInput({ roomId }: MessageInputProp) {
  const profile = useSelector(selectProfile)
  const { sendMessage } = useChatMessages(roomId)
  const [value, setValue] = useState('')

  const handleChange = (event: any) => {
    setValue(event.target.value)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (roomId && profile) {
      const message: Message = {
        profile: profile,
        roomId: roomId,
        message: value,
      }
      sendMessage(message)
    }

    setValue('')
  }

  return (
    <form onSubmit={handleSubmit} className="message-input-container">
      <input
        type="text"
        placeholder="Enter a message"
        value={value}
        onChange={handleChange}
        className="message-input"
        required
        minLength={1}
      />
      <button
        type="submit"
        disabled={value.length < 1}
        className={value.length < 1 ? 'send-message-disabled' : 'send-message'}
      >
        Send
      </button>
    </form>
  )
}
export { MessageInput }
