const MatchFatCarbSplit = (fatCarbSplit: string) => {
  let match

  if (fatCarbSplit) {
    switch (fatCarbSplit) {
      case 'First':
        match = [
          { fat: 0.5, carb: 0.5 },
          { fat: 0.25, carb: 0.75 },
        ]
        return match
      case 'Second':
        match = [
          { fat: 0.75, carb: 0.25 },
          { fat: 0.25, carb: 0.75 },
        ]
        return match

      case 'Third':
        match = [
          { fat: 0.8, carb: 0.2 },
          { fat: 0.2, carb: 0.8 },
        ]
        return match

      case 'Fourth':
        match = [
          { fat: 0.9, carb: 0.1 },
          { fat: 0.1, carb: 0.9 },
        ]
        return match

      default:
        match = [
          { fat: 0.25, carb: 0.75 },
          { fat: 0.75, carb: 0.25 },
        ]
        return match
    }
  }
  return match
}

export default MatchFatCarbSplit
