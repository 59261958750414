import React from 'react'
import { useSelector } from 'react-redux'
import { useChatMessages } from 'features/firebase/hooks'
import { MessageInputProp } from 'features/firebase/types'
import { selectProfile } from 'features/user/selectors'
import '../index.scss'

function Message({ message, isOwnMessage }: any) {
  const { displayName, text } = message
  return (
    <li className={['message', isOwnMessage && 'own-message'].join(' ')}>
      <h4 className="sender">{isOwnMessage ? 'You' : displayName}</h4>
      <div>{text}</div>
    </li>
  )
}

function MessageList({ roomId }: MessageInputProp) {
  const containerRef = React.useRef(null)
  const profile = useSelector(selectProfile)
  const { messages } = useChatMessages(roomId)

  React.useLayoutEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  })

  return (
    <div className="message-list-container" ref={containerRef}>
      <ul className="message-list">
        {profile &&
          messages &&
          messages.map((x: any) => (
            <Message key={x.id} message={x} isOwnMessage={x.uid === profile.uId} />
          ))}
      </ul>
    </div>
  )
}

export { MessageList }
