import React, { useCallback } from 'react'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'
import { FaFacebook } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import google_icon from 'assets/google_icon.png'
import Button from 'components/common/Layout/Button'
import LogoIcon from 'components/common/Layout/LogoIcon'
import { useAuth, useProviderSignIn } from 'features/user/hooks'
import { selectLoading } from 'features/user/selectors'
import { Contract } from 'features/user/types'
import { validationSchema } from 'pages/LoginPage/validation'
import MemberPage from 'pages/MemberPage'
import './index.scss'

const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<Contract.ExtendedProfile>({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema()),
  })
  const { loginUser, isLoggedIn, error, profile } = useAuth()
  const { loginWithGoogle, loginWithFacebook } = useProviderSignIn()
  const loading = useSelector(selectLoading)

  const validation = true
  const onSubmit = useCallback(
    handleSubmit((data) => {
      loginUser(data, true)
      reset()
    }),
    []
  )

  // add errorhandling message banners

  if (isLoggedIn && !error && profile) {
    return <MemberPage profile={profile} />
  }

  return (
    <div className="fatcalc-user-wrapper">
      <div className="fatcalc-user-container">
        <h2>Login to your Account</h2>
        <div className="fatcalc-form-col">
          <form onSubmit={onSubmit}>
            <input type="text" {...register('email')} placeholder="email" />
            {errors.email && <p>Email is required.</p>}
            <input type="text" {...register('password')} placeholder="password" />
            {errors.password && <p>Password is required.</p>}
            <Button
              text={'LOGIN'}
              disabled={!validation && !isValid}
              validation={validation && isValid}
              loading={loading}
              type="submit"
            />
          </form>
          <div className="c3dd47fde">
            <span style={{ margin: '0 10px', paddingBottom: '20px' }}>Or</span>
          </div>
          <div className="firebase-provider-btn" onClick={() => loginWithGoogle(true)}>
            <div className="g-position">
              <img src={google_icon} alt="s" className="g-icon-size" />
            </div>{' '}
            Continue with Google
          </div>

          <div className="firebase-provider-btn" onClick={() => loginWithFacebook(true)}>
            <FaFacebook className="fb-icon-size" /> Continue with Facebook
          </div>
        </div>

        <p>Forgot your password?</p>
        <p>
          Don't have an account?{' '}
          <Link style={{ color: 'white' }} to="/create">
            Register for free!{' '}
          </Link>
        </p>
      </div>
    </div>
  )
}
export default LoginPage
