import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import NotificationBanner from 'components/common/Layout/NotificationBanner/NotificationBanner'
import { selectMessage } from 'features/message/selectors'
import { Message } from 'features/message/types'

/**
 * MessageHub
 * used for relaying communication between backend, frontend and end-user.
 * TODO: add funcationality for sending logs to backend.
 */
const MessageHub: React.FC = () => {
  const message = useSelector(selectMessage)
  const render = useCallback((message: Message) => {
    return (
      <NotificationBanner
        id={message.id}
        show={message.visible}
        text={message.message}
        buttonText={message.options.buttonText}
        exitTimer={message.timeout}
        severity={message.severity === 'error' ? 'error' : 'info'}
        color={message.severity === 'error' ? '#e00751' : '#0058a3'}
      />
    )
  }, [])
  return message ? render(message) : null
}

export default MessageHub
