import {
  FetchDietSuccess,
  FetchLiftDaysSuccess,
  FetchRestdayKcalSuccess,
  FetchRestdaySuccess,
  FetchTdeeBegin,
  FetchTdeeError,
  FetchTdeeSuccess,
  FetchTeeSuccess,
  FetchTrainingdayKcalSuccess,
  FetchTrainingdaySuccess,
  FetchWeightSuccess,
} from './types'

export const fetchTdeeBegin = (): FetchTdeeBegin => ({
  type: 'FETCH_TDEE_BEGIN',
})

export const fetchTdeeSuccess = (tdee: number): FetchTdeeSuccess => ({
  type: 'FETCH_TDEE_SUCESS',
  payload: { tdee },
})

export const fetchTdeeError = (error: string): FetchTdeeError => ({
  type: 'FETCH_TDEE_ERROR',
  payload: { error },
})

export const fetchTeeSuccess = (tee: number): FetchTeeSuccess => ({
  type: 'FETCH_TEE_SUCESS',
  payload: { tee },
})

export const fetchDietSuccess = (diet: string): FetchDietSuccess => ({
  type: 'FETCH_DIET_SUCESS',
  payload: { diet },
})
export const fetchLiftDaysSuccess = (liftDays: number): FetchLiftDaysSuccess => ({
  type: 'FETCH_LIFT_DAYS_SUCESS',
  payload: { liftDays },
})
export const fetchRestdaySuccess = (restday: number): FetchRestdaySuccess => ({
  type: 'FETCH_RESTDAY_SUCESS',
  payload: { restday },
})

export const fetchTrainingdaySuccess = (trainingday: number): FetchTrainingdaySuccess => ({
  type: 'FETCH_TRAININGDAY_SUCESS',
  payload: { trainingday },
})
export const fetchWeightSuccess = (weight: number): FetchWeightSuccess => ({
  type: 'FETCH_WEIGHT_SUCESS',
  payload: { weight },
})

export const fetchRestdayKcalSuccess = (restDaySpecKcal: number): FetchRestdayKcalSuccess => ({
  type: 'FETCH_RESTDAYKCAL_SUCESS',
  payload: { restDaySpecKcal },
})

export const fetchTrainingdayKcalSuccess = (
  trainingDaySpecKcal: number
): FetchTrainingdayKcalSuccess => ({
  type: 'FETCH_TRAININGDAYKCAL_SUCESS',
  payload: { trainingDaySpecKcal },
})
