import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import validator from 'validator'
import * as MessageActions from 'features/message/actions'
import { useSearchAccount } from 'features/user/hooks'
import Button from '../Layout/Button'
import './index.scss'

export interface ErrorResponse {
  errorCode: string
  errorMessage: string
}

/**
 * @returns Search component.
 *
 *
 */
const Search: React.FC = () => {
  const [termError, setTermError] = useState('')
  const [term, setTerm] = useState('')
  const { searchAccount, loading, error, success, user } = useSearchAccount()
  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      switch (error?.errorType) {
        case 'NONE-FOUND':
          dispatch(
            MessageActions.sendError(error.errorMessage, {
              error: error.errorType,
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          break
        case 'UNKOWN-ERROR':
          dispatch(
            MessageActions.sendError(error.errorMessage, {
              error: error.errorType,
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          break

        default:
          dispatch(
            MessageActions.sendError(error.errorMessage, {
              error: error.errorType,
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          break
      }
    }
  }, [dispatch, error])

  const handleSubmit = async () => {
    if (term) {
      searchAccount(term, true)
        .then((response) => {
          console.log('search res', response)
        })
        .finally(() => {
          setTerm('')
        })
      return Promise.resolve()
    }
  }

  return (
    <>
      <div>
        <input
          type="text"
          id="searchTerm"
          value={term}
          placeholder="Enter username or email"
          required
          onChange={(e) => setTerm(e.target.value)}
          onBlur={(e) => {
            if (!validator.matches(e.target.value, /^[a-zA-Z0-9\._@]{3,30}$/)) {
              setTermError('Please enter a valid name or email')
            } else {
              setTermError('')
            }
          }}
        />
        <p>{termError && termError}</p>
        <Button
          onClick={() => handleSubmit()}
          text={'SEARCH'}
          disabled={term.length > 0 && termError.length <= 0 ? false : true}
          validation={term.length > 0 && termError.length <= 0 ? true : false}
          loading={loading}
        />
      </div>
      <div>
        {success && user ? (
          <div className="search-wrapper">
            <p>Search results</p>
            <p>Username: {user.userName}</p>
            <p>Email: {user.email}</p>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
export default Search
