import React, { useState, ChangeEvent, useEffect } from 'react'
import './RadiobuttonGroup.scss'

export interface RadioProps {
  onDiet: (selected: string) => void
}

const RadioButton = (props: {
  label: string
  checked: boolean
  id: string
  name: string
  disabled: boolean
  onChange: (e: ChangeEvent<HTMLElement>) => void
}) => {
  return <input {...props} type="radio" />
}

const RadiobuttonGroup: React.FC<RadioProps> = ({ onDiet }) => {
  const [selected, setSelected] = useState('leanMass')

  useEffect(() => {
    onDiet(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const list = [
    {
      label: 'More Mass',
      id: 'agleanMass',
      name: 'groupRadios2',
      disabled: false,
    },
    {
      label: 'Lean Mass',
      id: 'leanMass',
      name: 'groupRadios1',
      disabled: false,
    },

    {
      label: 'Weight loss',
      id: 'weightloss',
      name: 'groupRadios3',
      disabled: false,
    },
    {
      label: 'More loss',
      id: 'agweightloss',
      name: 'groupRadios4',
      disabled: false,
    },
  ]

  return (
    <>
      <div className="radio-toolbar">
        {list.map(item => (
          <>
            <RadioButton
              {...item}
              key={item.id}
              onChange={() => setSelected(item.id)}
              checked={selected === item.id}
            />
            <label htmlFor={item.id}>{item.label}</label>
          </>
        ))}
      </div>
    </>
  )
}

export default RadiobuttonGroup
