import { FcAppState, FatCalcFormAction } from './types'

const initialState: FcAppState = {
  tdee: 0,
  wkTee: 0,
  loading: false,
  error: null,
  diet: '',
  liftDays: 0,
  restday: 0,
  trainingday: 0,
  weight: 0,
  restDaySpecKcal: 0,
  trainingDaySpecKcal: 0,
}

export default function reducer(state = initialState, action: FatCalcFormAction): FcAppState {
  switch (action.type) {
    case 'FETCH_TDEE_BEGIN':
      return {
        ...state,
        tdee: 0,
        loading: true,
        error: null,
      }

    case 'FETCH_TDEE_SUCESS':
      return {
        ...state,
        loading: false,
        tdee: action.payload.tdee,
      }

    case 'FETCH_TDEE_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        tdee: 0,
      }

    case 'FETCH_TEE_SUCESS':
      return {
        ...state,
        loading: false,
        wkTee: action.payload.tee,
      }

    case 'FETCH_DIET_SUCESS':
      return {
        ...state,
        loading: false,
        diet: action.payload.diet,
      }
    case 'FETCH_LIFT_DAYS_SUCESS':
      return {
        ...state,
        loading: false,
        liftDays: action.payload.liftDays,
      }
    case 'FETCH_RESTDAY_SUCESS':
      return {
        ...state,
        loading: false,
        restday: action.payload.restday,
      }
    case 'FETCH_TRAININGDAY_SUCESS':
      return {
        ...state,
        loading: false,
        trainingday: action.payload.trainingday,
      }
    case 'FETCH_WEIGHT_SUCESS':
      return {
        ...state,
        loading: false,
        weight: action.payload.weight,
      }
    case 'FETCH_RESTDAYKCAL_SUCESS':
      return {
        ...state,
        loading: false,
        restDaySpecKcal: action.payload.restDaySpecKcal,
      }
    case 'FETCH_TRAININGDAYKCAL_SUCESS':
      return {
        ...state,
        loading: false,
        trainingDaySpecKcal: action.payload.trainingDaySpecKcal,
      }
    default:
      return state
  }
}
