import { Action } from 'redux'

export const ACTION_SET_LOADING = 'app/ACTION_SET_LOADING'

export interface AppState {
  loading: boolean
}

export interface SetLoadingAction extends Action<typeof ACTION_SET_LOADING> {
  payload: boolean
}

export type AppAction = SetLoadingAction
// | InitAction
// | SetInitStateAction
// | SetLanguageAction
// | SetSessionValuesAction
// | SetConfigurationAction
// | RestartAction
// | ShowNewMemberModalAction
// | EndSessionAction
