import { useSelector } from 'react-redux'
import { selectWeeklyTee } from 'features/fatcalc/selectors'
import CalcDietScheme from './CalcDietScheme'

const CalcKcalDiff = () => {
  const wkTee = useSelector(selectWeeklyTee)
  const currentDietScheme = CalcDietScheme()
  const diff = currentDietScheme - wkTee

  return diff
}

export default CalcKcalDiff
