export interface Props {
  bmr: number
  activityLevel: number
}

const CalcTDEE = ({ bmr, activityLevel }: Props) => {
  const results = bmr * activityLevel

  return results
}

export default CalcTDEE
