import { useState, useCallback } from 'react'
import axios from 'axios'
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import * as AppActions from 'features/app/actions'
import CONSTANTS from 'features/constants'
import * as MessageActions from 'features/message/actions'
import * as UserActions from 'features/user/actions'
import { Contract } from 'features/user/types'
import getFirebase from 'services'
import API from '../../api'
import { selectIsLoggedIn, selectProfile, selectUserIdentifier } from './selectors'
import { ErrorResponse, UserEventType } from './types'

/**
 * Hook for signing up a new user.
 *
 * @param search the search term.
 * @param valiation a form validation bool.
 */
export function useSearchAccount() {
  const [error, setError] = useState<ErrorResponse>()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [user, setUser] = useState<Partial<Contract.BaseProfile>>({
    userName: '',
    email: '',
  })
  const dispatch = useDispatch()

  const searchAccount = useCallback(async (search: string, validation: boolean) => {
    setSuccess(false)
    if (validation) {
      try {
        setLoading(true)
        setError(undefined)
        const response = await API.searchUser(search)
        setUser({
          userName: response.data.payload.userName,
          email: response.data.payload.email,
        })
        setSuccess(true)
        dispatch(
          MessageActions.sendMessage(response.data.successCode, {
            buttonText: 'Close',
            showCountdown: true,
            timeout: 10000,
          })
        )
      } catch (e: any) {
        if (!e.response) {
          setError({
            errorCode: 'SOMETHING_WENT_WRONG',
            errorType: 'ERROR-NO-RESPONSE',
            errorMessage: 'Something went wrong, please try again later',
          } as ErrorResponse)
        } else {
          setError(e.response.data as ErrorResponse)
        }
      } finally {
        setLoading(false)
        dispatch(AppActions.setLoading(false))
      }
    }
  }, [])
  return { searchAccount, loading, error, success, user }
}

/**
 * Hook for signing up a new user.
 *
 * @param user the user profile information.
 * @param valiation a form validation bool.
 */
export function useSignupAccount() {
  const [error, setError] = useState<ErrorResponse>()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const user = useSelector(selectProfile)
  const dispatch = useDispatch()

  const signupAccount = useCallback(async (user: Contract.ExtendedProfile, validation: boolean) => {
    setSuccess(false)
    if (validation) {
      try {
        setLoading(true)
        /* keep until global loading is finished*/
        //dispatch(AppActions.setLoading(true))
        setError(undefined)
        const response = await API.createUser(user)

        dispatch(
          UserActions.setProfile(
            await response.data.success.payload,
            response.data.success.payload.email
          )
        )
        setSuccess(true)
      } catch (e: any) {
        if (!e.response) {
          setError({
            errorCode: 'SOMETHING_WENT_WRONG',
            errorType: 'ERROR-NO-RESPONSE',
            errorMessage: 'Something went wrong, please try again later',
          } as ErrorResponse)
        } else {
          setError(e.response.data as ErrorResponse)
        }
      } finally {
        setLoading(false)
        dispatch(AppActions.setLoading(false))
      }
    }
  }, [])
  return { signupAccount, loading, error, success, user }
}

/**
 * Use auth hook.
 *
 * @returns the profile state props.
 */
export function useAuth() {
  const [error, setError] = useState<ErrorResponse>()
  const [loading, setLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const profile = useSelector(selectProfile)
  const userIdentifier = useSelector(selectUserIdentifier)

  const dispatch = useDispatch()

  // get cookie/session valid
  // const getAccessToken = useCallback(
  //   (target: Routes) => {
  //     if (!accessToken) {
  //       dispatch(Actions.sendEvent({ type: AuthEventType.GetAccessToken, data: target }))
  //     }
  //   },
  //   [dispatch, accessToken]
  // )

  const firebaseAuthHandler = useCallback(() => {
    let auth
    auth = getAuth(getFirebase())
    onAuthStateChanged(auth, (user) => {
      // Check for user status

      if (user && !profile) {
        dispatch(UserActions.sendEvent({ type: UserEventType.ProviderLogin, data: { user } }))
      }
    })
    return auth
  }, [])

  const loginUser = useCallback(
    (user: Contract.ExtendedProfile, validation: boolean) => {
      dispatch(UserActions.sendEvent({ type: UserEventType.Login, data: { user, validation } }))
    },
    [dispatch]
  )

  const logoutUser = useCallback(() => {
    dispatch(UserActions.sendEvent({ type: UserEventType.Logout }))
  }, [dispatch])

  const getAuthCookies = useCallback(async () => {
    try {
      const res = await axios.get(`${CONSTANTS.API_HOST}/test-cookies`, { withCredentials: true })

      dispatch(UserActions.setIdentifier(res.data.success.payload))
      setIsAuthenticated(true)
    } catch (e: any) {
      if (!e.response) {
        setError({
          errorCode: 'SOMETHING_WENT_WRONG',
          errorType: 'ERROR-NO-RESPONSE',
          errorMessage: 'Something went wrong, please try again later',
        } as ErrorResponse)
      } else {
        setError(e.response.data as ErrorResponse)
      }
    } finally {
      setLoading(false)
      dispatch(AppActions.setLoading(false))
    }
  }, [])

  return {
    profile,
    isLoggedIn,
    firebaseAuthHandler,
    // accessToken,
    // memberId,
    // refreshProfile,
    loading,
    loginUser,
    logoutUser,
    getAuthCookies,
    // getProfile,
    // logoutUser,
    // getAccessToken,
    isAuthenticated,
    userIdentifier,
    error,
  }
}

/**
 * Hook for signing in a new user with google.
 *
 * @param user the user profile information.
 * @param valiation a form validation bool.
 */
export function useProviderSignIn() {
  const dispatch = useDispatch()

  const loginWithFacebook = useCallback(async (validation: boolean) => {
    if (validation) {
      try {
        const provider = new FacebookAuthProvider()

        const auth = getAuth(getFirebase())

        // const { user } = await signInWithPopup(auth, new FacebookAuthProvider())
        const { user } = await signInWithPopup(auth, provider)
        console.log('guser', user)
        dispatch(UserActions.sendEvent({ type: UserEventType.ProviderLogin, data: { user } }))
        return { uid: user.uid, displayName: user.displayName }
      } catch (error: any) {
        if (error.code !== 'auth/cancelled-popup-request') {
          console.error(error)
        }

        return null
      } finally {
        // setLoading(false)
      }
    }
  }, [])

  const loginWithGoogle = useCallback(
    async (validation: boolean) => {
      if (validation) {
        try {
          const provider = new GoogleAuthProvider()

          const auth = getAuth(getFirebase())

          const { user } = await signInWithPopup(auth, provider)
          console.log('guser', user)
          dispatch(UserActions.sendEvent({ type: UserEventType.ProviderLogin, data: { user } }))
          return { uid: user.uid, displayName: user.displayName }
        } catch (error: any) {
          if (error.code !== 'auth/cancelled-popup-request') {
            console.error(error)
          }

          return null
        } finally {
          // setLoading(false)
          // dispatch(AppActions.setLoading(false))
        }
      }
    },
    [dispatch]
  )
  return { loginWithGoogle, loginWithFacebook }
}
