import { Action } from 'redux'
import { ErrorTypes } from 'features/types'

export const ACTION_SET_ACCESS_TOKEN = 'user/ACTION_SET_ACCESS_TOKEN'
export const ACTION_REFRESH_PROFILE = 'user/ACTION_REFRESH_PROFILE'
export const ACTION_SET_PROFILE = 'user/ACTION_SET_PROFILE'
export const ACTION_SET_LOADING = 'user/ACTION_SET_LOADING'
export const ACTION_SET_LOGGED_IN = 'user/ACTION_SET_LOGGED_IN'
export const ACTION_SEND_EVENT = 'user/ACTION_SEND_EVENT'
export const ACTION_SET_EVENT = 'user/ACTION_SET_EVENT'
export const ACTION_SET_IDENTIFIER = 'user/ACTION_SET_IDENTIFIER'

export type LoggedInState = 'loading' | 'yes' | 'no'

export enum UserEventType {
  Login = 'login',
  Logout = 'logout',
  ForceLogout = 'forceLogout',
  GetAccessToken = 'getAccessToken',
  GetProfile = 'getProfile',
  RefreshProfile = 'refreshProfile',
  GetSession = 'getSession',
  ProviderLogin = 'providerLogin',
}

/**
 * An event with an intention to do something.
 */
interface NamedEvent<T extends UserEventType> {
  type: T
}
/**
 * An event with an intent to do something accompanied with some data.
 */
interface NamedEventWithData<T extends UserEventType, U> extends NamedEvent<T> {
  data: U
  event?: U
}

export type UserEvent =
  | LoginEvent
  | ProviderLoginEvent
  | LogoutEvent
  | ForceLogoutEvent
  | GetProfileEvent
  | RefreshProfileEvent
  | GetSession

export interface UserState {
  event?: UserEvent
  loading: boolean
  profile?: Contract.BaseProfile
  loggedInState: LoggedInState
  accessToken?: string
  identifier?: string
  uId?: string
}

export interface ForceLogoutEvent extends NamedEvent<UserEventType.ForceLogout> {}
export interface LoginEvent extends NamedEventWithData<UserEventType.Login, any> {}
export interface ProviderLoginEvent extends NamedEventWithData<UserEventType.ProviderLogin, any> {}
export interface LogoutEvent extends NamedEvent<UserEventType.Logout> {}
export interface GetProfileEvent extends NamedEvent<typeof UserEventType.GetProfile> {}
export interface GetSession extends NamedEvent<UserEventType.GetSession> {}

export interface RefreshProfileEvent extends NamedEvent<UserEventType.RefreshProfile> {}
export interface ErrorResponse {
  errorCode: string
  errorMessage: string
  errorType: ErrorTypes
}

export namespace Contract {
  export interface BaseProfile {
    uId: string
    userName: string
    email: string
    provider: AuthProvider | string
  }
  export enum AuthProvider {
    Google = 'google',
    MongoDb = 'mongodb',
    Facebook = 'facebook',
  }

  export type ExtendedProfile = Partial<BaseProfile> & {
    password: string
  }
  export enum FieldNames {
    Password = 'password',
  }
  export interface FormValues {
    [FieldNames.Password]: string
  }
}

export interface SetProfileAction extends Action<typeof ACTION_SET_PROFILE> {
  payload: {
    profile: Contract.BaseProfile
    uId: string
  }
}
export interface SetAccessTokenAction extends Action<typeof ACTION_SET_ACCESS_TOKEN> {
  payload: string
}

export interface SetIdentifierAction extends Action<typeof ACTION_SET_IDENTIFIER> {
  payload: string
}

export interface SetLoadingAction extends Action<typeof ACTION_SET_LOADING> {
  payload: boolean
}
export interface SetLoggedInAction extends Action<typeof ACTION_SET_LOGGED_IN> {
  payload: boolean
}
export interface SendEventAction extends Action<typeof ACTION_SEND_EVENT> {
  payload: UserEvent
}

export interface SetEventAction extends Action<typeof ACTION_SET_EVENT> {
  payload: UserEvent
}
export interface SetLoadingAction extends Action<typeof ACTION_SET_LOADING> {
  payload: boolean
}

export type UserAction =
  | SetProfileAction
  | SetAccessTokenAction
  | SetLoadingAction
  | SetLoggedInAction
  | SendEventAction
  | SetEventAction
  | SetIdentifierAction
