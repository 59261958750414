import React, { useState, useEffect, useCallback } from 'react'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from 'components/common/Layout/Button'
import LoadingComponent from 'components/common/LoadingComponent'
import * as MessageActions from 'features/message/actions'
import { useSignupAccount } from 'features/user/hooks'
import { Contract } from 'features/user/types'
import LoginPage from 'pages/LoginPage/index'
import { validationSchema } from 'pages/SignupPage/validation'
import { Routes } from 'routes/types'
import './SignUpPage.scss'

const SignUpPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<Contract.ExtendedProfile>({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema()),
  })
  const { signupAccount, loading, error, success } = useSignupAccount()
  const [validation, setValidation] = useState(true)
  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setValidation(true)
    } else {
      setValidation(false)
    }
  }, [errors.userName, errors.password, errors.email])

  useEffect(() => {
    if (error) {
      switch (error?.errorType) {
        case 'PASSWORD-CONTAINS-USER-INFO':
          dispatch(
            MessageActions.sendError(error.errorMessage, {
              error: error.errorType,
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          break
        case 'PASSWORD-TOO-COMMON': // Fall-through
        case 'PASSWORD-TOO-WEAK':
          dispatch(
            MessageActions.sendError(error.errorMessage, {
              error: error.errorType,
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          break
        case 'USER-ALREADY-EXISTS':
          dispatch(
            MessageActions.sendError(error.errorMessage, {
              error: error.errorType,
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          break
        case 'VALIDATION-ERROR-MOBILE-MAXLENGTH': // Fall-through
        case 'VALIDATION-ERROR': // Fall-through
        case 'ERROR':
          dispatch(
            MessageActions.sendError(error.errorMessage, {
              error: error.errorType,
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          break
        case 'UNKOWN-ERROR': // Fall-through
        case 'ERROR-NO-RESPONSE': // Fall-through
        default:
          dispatch(
            MessageActions.sendError(error.errorMessage, {
              error: error.errorType,
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          break
      }
    }
  }, [dispatch, error])

  const onSubmit = useCallback(
    handleSubmit((data) => {
      signupAccount(data, validation)
      reset()
    }),
    []
  )

  if (success && !error) {
    return <LoginPage />
  }

  return (
    <div className="fatcalc-user-wrapper">
      <h2>Create an Account</h2>
      <div className="fatcalc-form-col">
        <form onSubmit={onSubmit}>
          <input type="text" {...register('userName')} placeholder="username" />
          {errors.userName && <p>Username is required.</p>}
          <input type="text" {...register('email')} placeholder="email" />
          {errors.email && <p>Email is required.</p>}
          <input type="text" {...register('password')} placeholder="password" />
          {errors.password && <p>Password is required.</p>}
          <Button
            text={'CREATE'}
            disabled={!validation && !isValid}
            validation={validation && isValid}
            loading={loading}
            type="submit"
          />
        </form>
      </div>
    </div>
  )
}
export default SignUpPage
