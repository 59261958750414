import { Action } from 'redux'
import { Routes } from 'routes/types'

export const ACTION_SEND_MESSAGE = 'message/ACTION_SEND_MESSAGE'
export const ACTION_SEND_ERROR = 'message/ACTION_SEND_ERROR'
export const ACTION_SHOW_MESSAGE = 'message/ACTION_SHOW_MESSAGE'
export const ACTION_HIDE = 'message/ACTION_HIDE'

export enum MessageSeverity {
  Error = 'error',
  Info = 'info',
}

export interface Message {
  id: string
  visible: boolean
  severity: MessageSeverity
  message: string
  buttonText?: string
  timeout: number
  showCountDown?: boolean
  options?: any
}
export interface MessageState {
  message?: Message
  logsInFlight: string[]
}
export interface MessageEventActions {
  navigate?: Routes
  actions?: Action[]
}

export interface MessageOptions {
  buttonText?: string
  dismissActions?: MessageEventActions
  timeoutActions?: MessageEventActions
  postActions?: Action[]
  timeout?: number
  showCountdown?: boolean
}

export interface ErrorMessageOptions extends MessageOptions {
  sendLog?: boolean
  error?: any
  reportOnly?: boolean
}

// export interface SendMessageAction extends Action<typeof ACTION_SEND_MESSAGE> {
export interface SendMessageAction extends Action<typeof ACTION_SHOW_MESSAGE> {
  // meta: {
  //   id: string
  //   message: string
  //   options?: MessageOptions
  // }
  payload: {
    id: string
    created: string
    message: string
    options?: ErrorMessageOptions
    visible: boolean
    severity: MessageSeverity.Info
    timeout: number
  }
}

export interface SendErrorAction extends Action<typeof ACTION_SHOW_MESSAGE> {
  payload: {
    id: string
    created: string
    message: string
    options?: ErrorMessageOptions
    visible: boolean
    severity: MessageSeverity.Error
    timeout: number
  }
}

export interface HideAction extends Action<typeof ACTION_HIDE> {}

export interface ShowMessageAction extends Action<typeof ACTION_SHOW_MESSAGE> {
  payload: Message
}

export type MessageActions = SendMessageAction | SendErrorAction | ShowMessageAction | HideAction
