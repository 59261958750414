import * as joi from 'joi'

export const validationSchema = () => {
  const schema = joi
    .object({
      userName: joi
        .string()
        .regex(/^[A-Za-z0-9]*$/)
        .required(),
      email: joi
        .string()
        .min(3)
        // .regex(/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/)
        .required()
        .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }),
      password: joi
        .string()
        .required()
        .min(8)
        .max(25)
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'password'),
    })
    .required()

  return schema
}
