import { ACTION_HIDE, ACTION_SHOW_MESSAGE, MessageActions, MessageState } from './types'

const initState: MessageState = {
  logsInFlight: [],
}

export default function reducer(state = initState, action: MessageActions): MessageState {
  switch (action.type) {
    case ACTION_SHOW_MESSAGE:
      return {
        ...state,
        message: action.payload,
      }
    case ACTION_HIDE:
      if (state.message) {
        return {
          ...state,
          message: { ...state.message, visible: false },
        }
      }
      return state
    default:
      return state
  }
}
