import React from 'react'
import './Box.scss'

export interface BoxProps {
  label?: string
  children?: React.ReactNode
}

const Box: React.FC<BoxProps> = ({ children }) => {
  return <div className="box-component">{children}</div>
}

export default Box
