import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import infoIcon from 'assets/info-circle-solid.svg'
import Box from 'components/common/Layout/Box/Box'
import FooterLink from 'components/common/Layout/FooterLink'
import FrontTemplate from 'components/common/template/FrontTemplate'
import FatCalcForm, { CalcStats } from 'components/form/FatCalcForm/FatCalc'
import { fetchTdeeSuccess } from 'features/fatcalc/actions'
import './LandingPage.scss'

const LandingPage: React.FC = () => {
  const [stateValue, setStateValue] = useState<CalcStats>({
    bmr: 0,
    bmi: 0,
    lbm: 0,
    tdee: 0,
    bmiValue: '',
  })
  const dispatch = useDispatch()
  const { tdee } = stateValue

  useEffect(() => {
    dispatch(fetchTdeeSuccess(tdee))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tdee])

  return (
    <FrontTemplate
      extra={
        <div className="left-content">
          <div className="lp-top-content"></div>
          <h2>Welcome to FatCalc</h2>
          <div className="fc-lp-top-box">Feel free to check out the 1RM Calc!</div>
          Please enter your details to quantify your bodyweight.
          <p> Keep in mind we are using metric units with kg's and cm's. </p>
          <FatCalcForm onUpdate={(e) => setStateValue(e)} />
        </div>
      }
    >
      {/* quantification */}
      <div className="right-content">
        <Box>
          <div className="fc-lp-box-wrapper">
            <div className="fc-lp-first-block">
              <div className="fc-lp-flex-row">
                <div className="fc-lp-med-header">Your BMR</div>
                <div className="tooltip">
                  <img className="info-icon-lp" src={infoIcon} alt="info" />
                  <span className="tooltiptext">
                    <div>
                      Basal metabolic rate is the number of calories your body needs to accomplish
                      its most basic (basal) life-sustaining functions, such as breathing, cell
                      production and blood circulation.
                    </div>
                  </span>
                </div>
              </div>

              <div>Your Basal Metabolic Rate</div>
            </div>
            <div className="fc-lp-second-block">
              <span className="fc-land-page-digits">{stateValue.bmr.toFixed(0)} Kcal</span>
            </div>
          </div>
        </Box>
        <Box>
          <div className="fc-lp-box-wrapper">
            <div className="fc-lp-first-block">
              <div className="fc-lp-flex-row">
                <div className="fc-lp-med-header">Your TDEE</div>
                <div className="tooltip">
                  <img className="info-icon-lp" src={infoIcon} alt="info" />
                  <span className="tooltiptext">
                    <div>
                      TDEE is the total number of calories your body is burning each day – and, by
                      extension, the number of calories that you'd need to eat each day to maintain
                      your current weight.
                    </div>
                  </span>
                </div>
              </div>

              <div>Your Total Daily Energy Expenditure</div>
            </div>
            <div className="fc-lp-second-block">
              <span className="fc-land-page-digits">{stateValue.tdee.toFixed(0)} Kcal</span>
            </div>
          </div>
        </Box>
        <Box>
          <div className="fc-lp-box-wrapper">
            <div className="fc-lp-first-block">
              <div className="fc-lp-flex-row">
                <div className="fc-lp-med-header">Your LBM</div>
                <div className="tooltip">
                  <img className="info-icon-lp" src={infoIcon} alt="info" />
                  <span className="tooltiptext">
                    <div>
                      The total weight of your bones, muscles, organs, connective tissue, skin,
                      water, etc. - everything but the fat.
                    </div>
                  </span>
                </div>
              </div>

              <div>Your Lean Body Mass</div>
            </div>
            <div className="fc-lp-second-block">
              <span className="fc-land-page-digits">{stateValue.lbm.toFixed(0)} Kg</span>
            </div>
          </div>
        </Box>
        <Box>
          <div className="fc-lp-box-wrapper">
            <div className="fc-lp-first-block">
              <div className="fc-lp-flex-row">
                <div className="fc-lp-med-header">Your BMI</div>
                <div className="tooltip">
                  <img className="info-icon-lp" src={infoIcon} alt="info" />
                  <span className="tooltiptext">
                    <div>
                      BMI is a calculation based on a persons weight and height that attempts to
                      give a heuristic proxy for human body fat. While considered reliable by the
                      CDC, it is often inaccurate for muscular athletes and shorter people and
                      children.
                    </div>
                  </span>
                </div>
              </div>

              <div>Your Body Mass Index</div>
              <b style={{ fontSize: '27px' }}>{stateValue.bmiValue}</b>
            </div>
            <div className="fc-lp-second-block">
              <span className="fc-land-page-digits"> {stateValue.bmi.toFixed(1)}</span>
            </div>
          </div>
        </Box>
        <FooterLink path="/macros" text="NEXT PAGE" homeIndicator={false} />
      </div>
    </FrontTemplate>
  )
}

export default LandingPage
