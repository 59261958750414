import { uniqueId } from 'lodash'
import {
  ACTION_SEND_MESSAGE,
  // ACTION_SEND_ERROR,
  ACTION_SHOW_MESSAGE,
  SendMessageAction,
  SendErrorAction,
  MessageOptions,
  ErrorMessageOptions,
  HideAction,
  ACTION_HIDE,
  MessageSeverity,
} from './types'

export function sendMessage(message: string, options?: MessageOptions): SendMessageAction {
  return {
    type: ACTION_SHOW_MESSAGE,
    payload: {
      id: uniqueId('msg'),
      created: new Date().toUTCString(),
      message,
      options,
      visible: true,
      severity: MessageSeverity.Info,
      timeout: options?.timeout ? options.timeout : 10000,
    },
  }
}

export function sendError(message: string, options?: ErrorMessageOptions): SendErrorAction {
  return {
    type: ACTION_SHOW_MESSAGE,
    payload: {
      id: uniqueId('msg'),
      created: new Date().toUTCString(),
      message,
      options,
      visible: true,
      severity: MessageSeverity.Error,
      timeout: options?.timeout ? options.timeout : 10000,
    },
  }
}

export function hideMessage(): HideAction {
  return {
    type: ACTION_HIDE,
  }
}
