import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import validator from 'validator'
import infoIcon from 'assets/info-circle-solid.svg'
import CalcBMI from 'components/common/CalcBMI'
import CalcBMR from 'components/common/CalcBMR'
import CalcBodyfat from 'components/common/CalcBodyfat'
import CalcLBM from 'components/common/CalcLBM'
import CalcTDEE from 'components/common/CalcTDEE'
import FormInputField from 'components/UX-lib/FormInputField/FormInputField'
import { fetchWeightSuccess } from 'features/fatcalc/actions'
import './FatCalc.scss'

export type State = {
  height: string
  weight: string
  age: string
  gender: string
  bodyfatCheckbox: boolean
  waist: string
  bodyfatPercentage: number
  activityLevel: string
}

export type CalcStats = {
  bmr: number
  bmi: number
  lbm: number
  tdee: number
  bmiValue: any
}

type Props = {
  onUpdate: (number: CalcStats) => void
}

const FatCalcForm: React.FC<Props> = ({ onUpdate }) => {
  const [formState, setFormState] = useState<State>({
    height: '',
    weight: '',
    age: '',
    gender: '',
    bodyfatCheckbox: false,
    waist: '',
    bodyfatPercentage: 0,
    activityLevel: '',
  })
  const [bodyfat, setBodyfat] = useState(0)
  const [heightError, setHeightError] = useState('')
  const [weightError, setWeightError] = useState('')
  const [ageError, setAgeError] = useState('')
  const [waistError, setWaistError] = useState('')
  const [validation, setValidation] = useState(false)

  const { weight } = formState
  const dispatch = useDispatch()

  useEffect(() => {
    const weight = Number(formState.weight)
    dispatch(fetchWeightSuccess(weight))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight])

  useEffect(() => {
    if (
      heightError === '' &&
      weightError === '' &&
      ageError === '' &&
      waistError === '' &&
      formState.height &&
      formState.weight &&
      formState.age &&
      formState.gender &&
      formState.waist &&
      formState.activityLevel
    ) {
      setValidation(true)
    } else {
      setValidation(false)
    }
  }, [
    heightError,
    weightError,
    ageError,
    waistError,
    formState.height,
    formState.weight,
    formState.age,
    formState.gender,
    formState.waist,
    formState.activityLevel,
  ])

  const handleChange = (formValue: Partial<State>) => {
    setFormState((currentState) => {
      return { ...currentState, ...formValue }
    })
  }
  const getBMIValue = (bmi: number) => {
    switch (true) {
      case bmi < 16.0:
        return 'Severely Underweight'
      case bmi === 16.0 || bmi < 18.5:
        return 'Underweight'
      case bmi === 18.5 || bmi < 25:
        return 'Normal'

      case bmi === 25 || bmi < 30:
        return 'Overweight'

      case bmi === 30 || bmi < 35:
        return 'Obese I'

      case bmi === 35 || bmi < 40:
        return 'Obese II'
      case bmi > 40:
        return 'Obese III'

      default:
        break
    }
  }
  const handleSubmit = (data: State) => {
    const weight = Number(data.weight)
    const height = Number(data.height)
    const age = Number(data.age)
    const { gender } = data
    const waist = Number(data.waist)
    const bodyfatPercentage = CalcBodyfat({ weight, waist, gender })
    const activityLevel = Number(data.activityLevel)
    const bodyfatCheckbox = Boolean(data.bodyfatCheckbox)
    if (waist && bodyfatCheckbox) {
      // eslint-disable-next-line no-param-reassign

      setBodyfat(bodyfatPercentage)
      handleChange({ bodyfatPercentage })
    }
    if (validation) {
      const resultsBmi = CalcBMI({ weight, height })
      const resultsBmr = CalcBMR({ weight, height, gender, age })
      const resultsLBM = CalcLBM({ weight, bodyfatPercentage })
      const resultsTDEE = CalcTDEE({ activityLevel, bmr: resultsBmr })
      onUpdate({
        bmr: resultsBmr,
        bmi: resultsBmi,
        lbm: resultsLBM,
        tdee: resultsTDEE,
        bmiValue: getBMIValue(resultsBmi),
      })
    }
  }
  return (
    <>
      <FormInputField
        id="height"
        value={formState.height}
        type="number"
        label="Height"
        required
        onChange={(e) => handleChange({ height: e.target.value })}
        onBlur={(e) => {
          if (!validator.matches(e.target.value, /^[0-9 ]+$/)) {
            setHeightError('Please enter a valid number')
          } else {
            setHeightError('')
          }
        }}
        errorMessage={heightError}
      />
      <FormInputField
        id="weight"
        value={formState.weight}
        type="number"
        label="Weight"
        required
        onChange={(e) => handleChange({ weight: e.target.value })}
        onBlur={(e) => {
          if (!validator.matches(e.target.value, /^[0-9 ]+$/)) {
            setWeightError('Please enter a valid number')
          } else {
            setWeightError('')
          }
        }}
        errorMessage={weightError}
      />
      <FormInputField
        id="age"
        value={formState.age}
        type="number"
        label="Age"
        required
        onChange={(e) => handleChange({ age: e.target.value })}
        onBlur={(e) => {
          if (!validator.matches(e.target.value, /^[0-9 ]+$/)) {
            setAgeError('Please enter a valid number')
          } else {
            setAgeError('')
          }
        }}
        errorMessage={ageError}
      />
      <div className="fc-lp-mid-box">
        <select
          className="fc-lp-dd"
          id="gender"
          value={formState.gender}
          required
          onChange={(e) => handleChange({ gender: e.target.value })}
        >
          <option>Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>

        <select
          id="activityLevel"
          className="fc-lp-dd"
          value={formState.activityLevel}
          required
          onChange={(e) => handleChange({ activityLevel: e.target.value })}
        >
          <option>Activity Level</option>
          <option value="1.2">Sedentary</option>
          <option value="1.375">Lightly Active</option>
          <option value="1.55">Moderately Active</option>
          <option value="1.725">Very Active</option>
          <option value="1.9">Extra Active</option>
        </select>
        {/* <div className="fc-lp-mid-box-text">
          <Popup>
            <div className="info-icon">i</div>
          </Popup>
        </div> */}
      </div>

      <div className="tooltip tt-extra-space">
        <img className="info-icon" src={infoIcon} alt="info" />
        <span className="tooltiptext">
          <div style={{ textAlign: 'left' }}>
            <p>Activty Level</p>
            This is your activity level, estimated as:
            <ul>
              <li>
                <b>Sedentary:</b> little or no exercise
              </li>
              <li>
                <b>Lightly Active: </b>light exercise/sports 1-3 days/week
              </li>
              <li>
                <b>Moderately Active:</b> moderate exercise/sports 3-5 days/week{' '}
              </li>
              <li>
                <b>Very Active:</b> hard exercise/sports 6-7 days a week
              </li>
              <li>
                <b>Extra Active:</b> very hard exercise/sports and physical job
              </li>
            </ul>
          </div>
        </span>
      </div>
      <div>
        <div className="bodyfat-box">
          <hr className="bodyfat-box-range" />
          <h3>Estimate Bodyfat</h3>
          <div className="bodyfat-holder">
            <div className="bodyfat-col">
              Your bodyfat:
              <div>
                <input
                  type="checkbox"
                  id="bodyfatCheckbox"
                  disabled={!formState.waist}
                  value={formState.bodyfatCheckbox ? 'true' : 'false'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange({ bodyfatCheckbox: e.target.checked })
                  }
                />{' '}
                Calculate
              </div>
            </div>
            <div className="bodyfat-value">{bodyfat.toFixed(2)}%</div>
          </div>
        </div>
        <FormInputField
          id="waist"
          value={formState.waist}
          type="number"
          label="Waist"
          onChange={(e) => handleChange({ waist: e.target.value })}
          onBlur={(e) => {
            if (!validator.matches(e.target.value, /^[0-9 ]+$/)) {
              setWaistError('Please enter a valid number')
            } else {
              setWaistError('')
            }
          }}
          errorMessage={waistError}
        />
      </div>
      <div
        className={validation ? 'btn btn-fatcalc' : 'btn btn-fatcalc fc-button-phase'}
        onClick={() => handleSubmit(formState)}
      >
        Submit
      </div>
    </>
  )
}
export default FatCalcForm
