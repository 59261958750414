import { createSelector } from 'reselect'
import { ApplicationState } from '../types'

const selectLocalState = (state: ApplicationState) => state.message

export const selectMessage = createSelector(selectLocalState, (state) => state.message)

export const selectIsSendingLogs = createSelector(
  selectLocalState,
  (state) => state.logsInFlight.length > 0
)
