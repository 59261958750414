import React from 'react'
import LoadingOverlay from 'react-loading-overlay-ts'
import { useSelector } from 'react-redux'
import { selectLoading } from 'features/app/selectors'
import './index.scss'

interface LoaderProps {
  children?: React.ReactNode
}

const LoadingComponent: React.FC<LoaderProps> = ({ children }) => {
  const isLoading = useSelector(selectLoading)
  return (
    <LoadingOverlay active={isLoading} spinner text="Loading your content...">
      {children}
    </LoadingOverlay>
  )
}
export default LoadingComponent
