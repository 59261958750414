import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import AuthenticationHub from 'components/common/AuthenticationHub'
import Navbar from 'components/common/Layout/Navbar/index'
import LoadingComponent from 'components/common/LoadingComponent'
import MessageHub from 'components/common/MessageHub'
import * as UserActions from 'features/user/actions'
import { useAuth } from 'features/user/hooks'
import { UserEventType } from 'features/user/types'
import BirthdayPage from 'pages/BirthdayPage'
import PageRoutes from 'routes'
import getFirebase from 'services'

const App: React.FC = () => {
  const { firebaseAuthHandler } = useAuth()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(UserActions.sendEvent({ type: UserEventType.GetSession }))
    /*  firebaseAuthHandler() */
  }, [])

  getFirebase()
  return (
    <>
      <MessageHub />
      <AuthenticationHub />
      <Navbar />
      <LoadingComponent>
        <PageRoutes />
      </LoadingComponent>
    </>
  )
}
export default App
