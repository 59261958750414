import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import IncorrectIcon from 'assets/error-icon.svg'
import InfoIcon from 'assets/info-circle-solid.svg'
import * as MessageActions from 'features/message/actions'
import styles from './NotificationBanner.module.scss'

export type BannerProps = {
  id: string
  show?: boolean
  text: string | JSX.Element
  buttonText?: string
  severity?: string
  iconSize?: string
  exitTimer: number
  color?: string
}

const NotificationBanner: React.FC<BannerProps> = React.memo(
  ({ id, text, buttonText, iconSize = '50', severity, exitTimer, color, show = true }) => {
    const [visible, setVisible] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
      if (show) {
        setVisible(true)
      }
    }, [show, id])

    useEffect(() => {
      if (!visible) {
        const timer = window.setTimeout(() => {
          setVisible(false)
          dispatch(MessageActions.hideMessage())
        }, exitTimer)
        return () => window.clearTimeout(timer)
      }
    }, [id])
    return visible ? (
      <div>
        <div className={styles.popUpContainer}>
          {severity && (
            <div className={styles.popupIcon}>
              <img
                src={severity === 'error' ? IncorrectIcon : InfoIcon}
                alt="communication icon"
                color={color}
                id={id}
                style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
              />
            </div>
          )}
          {text}
          <div className={styles.popUpBtn} onClick={() => setVisible(!visible)}>
            {buttonText}
          </div>
        </div>
        <div className={styles.overlay} />
      </div>
    ) : null
  }
)
export default NotificationBanner
