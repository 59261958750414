export enum Routes {
  Home = '/',
  OneRm = '/one-rm',
  Macros = '/macros',
  SignUp = '/create',
  Login = '/login',
  ChatRooms = '/room/:id',
  ChatPage = '/chat-page',
  Birthday = '/birthday',
}
