import { createSelector } from 'reselect'
import { ApplicationState } from '../types'
import { Contract } from './types'

const selectLocalState = (state: ApplicationState) => state.user

export const selectLoggedInState = createSelector(selectLocalState, (state) => state.loggedInState)
export const selectIsLoggedIn = createSelector(selectLoggedInState, (state) => state === 'yes')
export const selectEvent = createSelector(selectLocalState, (state) => state.event)
export const selectProfile = createSelector(selectLocalState, (state) => state.profile)
export const selectProvider = createSelector(selectLocalState, (state) => state.profile?.provider)
export const selectGoogleProvider = createSelector(
  selectLocalState,
  (state) => state.profile?.provider as Contract.AuthProvider.Google
)
export const selectFacebookProvider = createSelector(
  selectLocalState,
  (state) => state.profile?.provider as Contract.AuthProvider.Facebook
)
export const selectLoading = createSelector(selectLocalState, (state) => state.loading)
export const selectUserIdentifier = createSelector(selectLocalState, (state) => state.identifier)
