import * as joi from 'joi'

export const validationSchema = () => {
  const schema = joi
    .object({
      email: joi
        .string()
        .min(3)
        // .regex(/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/)
        .required()
        .email({ tlds: { allow: false } }),
      password: joi.string().required(),
      // .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'password'),
    })
    .required()

  return schema
}
