const chatRooms = [
  { id: 'health', title: 'Health ' },
  { id: 'nutrition', title: 'Nutrition ' },
  { id: 'general', title: ' General ' },
  { id: 'news', title: 'News ' },
  { id: 'strength', title: 'Strength ' },
  { id: 'sports', title: ' Sports ' },
]

export { chatRooms }
