import React, { useState } from 'react'
import Label from 'components/UX-lib/InputField/Label'
import './InputField.css'

interface InputFieldProps {
  id: string
  msg?: string
  label?: string
  name?: string
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  type?: 'text' | 'password' | 'email' | 'number'
  minLength?: number
  maxLength?: number
  min?: number
  max?: number
  pattern?: string
  errorMessage?: string
  value?: string
  ref?: any
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  required,
  onChange,
  onBlur,
  type,
  minLength,
  maxLength = 100,
  min,
  max,
  pattern,
  name,
  label,
  value,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [fieldActive, setFieldActive] = useState(false)

  // to activate the input field while typing
  const activateField = () => {
    setFieldActive(true)
  }

  const disableField = () => {
    setFieldActive(false)
  }

  // to deactivate input only if it's empty
  const disableFocus = (e: any) => {
    if (e.target.value === '') {
      setFieldActive(false)
    }
  }

  // to update the changes in the input and activate it
  const handleChange = (e: any) => {
    if (onChange !== undefined) {
      onChange(e)
    } else {
      return null
    }

    setInputValue(e.target.value)
    if (e.target.value === '') {
      disableField()
    } else {
      activateField()
    }
  }

  return (
    <div className="field-group">
      <Label
        // check state the input, whether it is active then apply the class for floating label
        className={fieldActive ? 'field-active' : ''}
        htmlFor={'text'}
      >
        {label}
      </Label>
      <input
        id={id}
        type={type}
        // label={label}
        name={name}
        required={required}
        value={inputValue}
        onFocus={activateField}
        onBlur={onBlur || disableFocus}
        // onBlur={disableFocus}
        onChange={handleChange}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        pattern={pattern}
      />
    </div>
  )
}

export default InputField
