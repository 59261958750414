import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import app from 'features/app/reducer'
import fatcalc from 'features/fatcalc/reducer'
import message from 'features/message/reducer'
import * as UserActions from 'features/user/actions'
import user from 'features/user/reducer'
import { UserEventType } from './user/types'

const rootReducer = combineReducers({
  fatcalc,
  message,
  app,
  user,
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store

export const test = {
  rootReducer,
  createMockStore: () => createStore(rootReducer),
}
