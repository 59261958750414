export interface Props {
  weight: number
  bodyfatPercentage: number
}

const CalcLBM = ({ weight, bodyfatPercentage }: Props) => {
  const fat = weight * (bodyfatPercentage / 100)
  const results = weight - fat

  return results
}

export default CalcLBM
