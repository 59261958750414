import React, { useState, useEffect } from 'react'
import validator from 'validator'
import CalcOneRM from 'components/common/CalcOneRM'
import FormInputField from 'components/UX-lib/FormInputField/FormInputField'
import './OneRm.css'

export type State = {
  lift: string
  reps: string
  weight: string
}

export type LiftStats = {
  lift: number
}

type Props = {
  onUpdate: (number: LiftStats) => void
}

const OneRm: React.FC<Props> = ({ onUpdate }) => {
  const [formState, setFormState] = useState<State>({
    lift: '',
    reps: '',
    weight: '',
  })
  const [liftError, setLiftError] = useState('')
  const [repError, setRepError] = useState('')
  const [validation, setValidation] = useState(false)

  useEffect(() => {
    if (liftError === '' && repError === '' && formState.lift && formState.reps) {
      setValidation(true)
    } else {
      setValidation(false)
    }
  }, [liftError, repError, formState.lift, formState.reps])

  const handleChange = (formValue: Partial<State>) => {
    setFormState((currentState) => {
      return { ...currentState, ...formValue }
    })
  }

  const handleSubmit = (data: State) => {
    const weight = Number(data.lift)
    const reps = Number(data.reps)
    if (validation) {
      const resultLift = CalcOneRM({ weight, reps })

      onUpdate({
        lift: resultLift,
      })
    }
  }

  return (
    <>
      <div className="one-rm-box">
        <select
          className="fc-lp-dd one-rm-dd"
          id="weight"
          value={formState.weight}
          required
          onChange={(e) => handleChange({ weight: e.target.value })}
        >
          <option value="kg">kg</option>
          <option value="lb">lb</option>
        </select>
        <FormInputField
          id="lift"
          value={formState.lift}
          type="number"
          label="Weight"
          required
          onChange={(e) => handleChange({ lift: e.target.value })}
          onBlur={(e) => {
            if (!validator.matches(e.target.value, /^[0-9 ]+$/)) {
              setLiftError('Please enter a valid number')
            } else {
              setLiftError('')
            }
          }}
          errorMessage={liftError}
        />
      </div>

      <FormInputField
        id="reps"
        value={formState.reps}
        type="number"
        label="Repititions"
        required
        onChange={(e) => handleChange({ reps: e.target.value })}
        onBlur={(e) => {
          if (!validator.matches(e.target.value, /^[0-9 ]+$/)) {
            setRepError('Please enter a valid number')
          } else {
            setRepError('')
          }
        }}
        errorMessage={repError}
      />
      <div
        className={validation ? 'btn btn-fatcalc' : 'btn btn-fatcalc fc-button-phase'}
        onClick={() => handleSubmit(formState)}
      >
        Submit
      </div>
    </>
  )
}
export default OneRm
