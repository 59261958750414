import React from 'react'
import { Route, Routes } from 'react-router-dom'
import BirthdayPage from 'pages/BirthdayPage'
import ChatPage from 'pages/ChatPage'
import ChatRoom from 'pages/ChatPage/ChatRoom'
import LandingPage from 'pages/LandingPage/LandingPage'
import LoginPage from 'pages/LoginPage'
import MacroPage from 'pages/MacroPage/MacroPage'
import OneRmPage from 'pages/OneRmPage/OneRmPage'
import SignupPage from 'pages/SignupPage'
import { Routes as RoutePaths } from './types'

const PageRoutes = () => (
  <Routes>
    <Route path={RoutePaths.Home} element={<LandingPage />} />
    <Route path={RoutePaths.Macros} element={<MacroPage />} />
    <Route path={RoutePaths.OneRm} element={<OneRmPage />} />
    <Route path={RoutePaths.Login} element={<LoginPage />} />
    <Route path={RoutePaths.SignUp} element={<SignupPage />} />
    <Route path={RoutePaths.ChatPage} element={<ChatPage />} />
    <Route path={RoutePaths.ChatRooms} element={<ChatRoom />} />
    <Route path={RoutePaths.Birthday} element={<BirthdayPage />} />
  </Routes>
)

export default PageRoutes
