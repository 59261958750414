import { useCallback, useEffect, useState } from 'react'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { database as db, getMessages } from 'services/index'

/**
 * Hook for utilizing chat.
 *
 * @param user the user profile information.
 * @param roomId a form validation bool.
 */
export function useChatMessages(roomId: string) {
  // const [error, setError] = useState<ErrorResponse>()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [messages, setMessages] = useState([])

  useEffect(() => {
    const unsubscribe = getMessages(roomId, setMessages)
    return unsubscribe
  }, [roomId])

  const sendMessage = useCallback(
    // async (roomId: string, user: Contract.ExtendedProfile, text: string) => {
    async (message: any) => {
      setSuccess(false)
      if (message.profile) {
        try {
          await addDoc(collection(db, 'chat-rooms', roomId, 'messages'), {
            uid: message.profile.uId,
            displayName: message.profile.userName,
            text: message.message.trim(),
            timestamp: serverTimestamp(),
          })
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false)
        }
      }
    },
    []
  )

  return { sendMessage, messages }
}
