import { useSelector } from 'react-redux'
import { selectLiftingDays, selectRestDays, selectTrainingDays } from 'features/fatcalc/selectors'

/**
 * Function for calculating kcal per day
 * @param day determines the chose day
 * @returns the amount of kcal per rest day or training day.
 */

const CalcKcalPerDay = (day: string) => {
  const liftDays = useSelector(selectLiftingDays)
  const restday = useSelector(selectRestDays)
  const trainingday = useSelector(selectTrainingDays)

  const workoutDaysKcal = trainingday / liftDays
  const restDaysKcal = restday / (7 - liftDays)

  const result = day === 'training' ? workoutDaysKcal : restDaysKcal

  return result.toFixed(0)
}

export default CalcKcalPerDay
