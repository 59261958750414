export interface Props {
  weight: number
  height: number
}

const CalcBMI = ({ weight, height }: Props) => {
  const results = weight / (((height / 100) * height) / 100)

  return results
}

export default CalcBMI
