import React from 'react'

export interface LabelProps {
  className?: string
  htmlFor: string
  children?: React.ReactNode
}

const Label: React.FC<LabelProps> = ({ className, htmlFor, children }) => {
  return (
    <label className={className} htmlFor={htmlFor}>
      {children}
    </label>
  )
}
export default Label
