import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { chatRooms } from 'data/chatRooms'
import { selectProfile } from 'features/user/selectors'
import './index.scss'

const ChatPage: React.FC = () => {
  const profile = useSelector(selectProfile)
  return (
    <div className="chat-page-wrapper">
      {profile ? (
        <>
          <h2>Choose a Chat Room</h2>
          <ul className="chat-room-list">
            {chatRooms.map((room) => (
              <li key={room.id}>
                <Link to={`/room/${room.id}`}>{room.title}</Link>
              </li>
            ))}
          </ul>{' '}
        </>
      ) : (
        <div className="chat-page-wrapper">
          {' '}
          <h2 style={{ color: '#2B2E4A' }}>Log in to join a chat room!</h2>
          <div className="btn btn-fatcalc">
            <Link style={{ textDecoration: 'none', color: 'white' }} to={`/login`}>
              Login
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
export default ChatPage
