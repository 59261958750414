import { FaSignInAlt, FaSignOutAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import FatCalc from 'assets/ny_logo_reg_2.png'
import * as UserActions from 'features/user/actions'
import { useAuth } from 'features/user/hooks'
import { selectProfile, selectProvider } from 'features/user/selectors'
import { Contract, UserEventType } from 'features/user/types'
import { Routes } from 'routes/types'
import { logout } from 'services'
import './index.scss'

export default function Navbar() {
  const { logoutUser } = useAuth()
  const dispatch = useDispatch()
  const profile = useSelector(selectProfile)
  const provider = useSelector(selectProvider)

  const logoutHandler = () => {
    if (provider) {
      logout()
      dispatch(UserActions.sendEvent({ type: UserEventType.Logout }))
    } else {
      logoutUser()
    }
  }
  return (
    <div className="header">
      <div className="logo">
        <Link to={Routes.Home}>
          <img className="logo" alt="FatCalcLogo" src={FatCalc} />
        </Link>
      </div>
      <nav>
        <ul>
          <li>
            <Link to={Routes.ChatPage}>
              <a>Chat</a>
            </Link>
          </li>
          <li>
            <Link to={Routes.OneRm}>
              <a>One Rep Max</a>
            </Link>
          </li>
          {/* TO-DO configure back when auth is fixed */}
          {profile ? (
            // if logged in
            <>
              <li>
                <Link to={Routes.Login}>
                  <a>Member</a>
                </Link>
              </li>
              <li>
                <div onClick={() => logoutHandler()} className="btn-div">
                  <a className="btn-icon">
                    <FaSignOutAlt />
                    Logout
                  </a>
                </div>
              </li>
            </>
          ) : (
            // if logged out
            <>
              <li className="nav-login-element">
                <Link to={Routes.Login}>
                  <div className="btn-secondary">
                    <a className="btn-icon">
                      <FaSignInAlt /> Login
                    </a>
                  </div>
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  )
}
