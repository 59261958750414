import axios from 'axios'
import { Contract } from 'features/user/types'
import CONSTANTS from './features/constants'

const client = axios.create({ timeout: 10000 })

const CREATE_USER_URL = `${CONSTANTS.API_HOST}/create-users`
const EVENT_HANDLER_URL = `${CONSTANTS.API_HOST}/event-handler`
const UPDATE_USER_URL = `${CONSTANTS.API_HOST}/update-user`
const SEARCH_USER_URL = `${CONSTANTS.API_HOST}/search-user`
// const AUTH_USER_URL = `${CONSTANTS.API_HOST}/login`
const AUTH_USER_URL = `${CONSTANTS.API_HOST}/login`
const READ_USER_URL = `${CONSTANTS.API_HOST}/read-user`
const LOGOUT_USER_URL = `${CONSTANTS.API_HOST}/logout-user`
const CHAT_MESSAGE_URL = `${CONSTANTS.API_HOST}/chat`

export default {
  createUser: (state: Contract.ExtendedProfile) => {
    const data = state
    return client.post(CREATE_USER_URL, data)
  },
  eventHandler: (state: any) => {
    const data = state
    return client.post(EVENT_HANDLER_URL, data)
  },
  updateUser: (state: any) => {
    const data = state

    return client.put(UPDATE_USER_URL, data)
  },
  searchUser: (state: any) => {
    const data = { value: state }

    return client.put(SEARCH_USER_URL, data)
  },

  authUser: (state: any) => {
    const data = state

    return client.post(AUTH_USER_URL, data, {
      withCredentials: true,
    })
  },
  readUser: (props: string, uId?: string) => {
    const data = { props, uId }

    return client.post(READ_USER_URL, data)
  },
  logoutUser: (email: string, uId?: string) => {
    const data = { email, uId }

    const custom = { ...data, withCredentials: true }

    return client.post(LOGOUT_USER_URL, custom)
  },
  chatMessage: (props: any) => {
    const data = { ...props }

    const custom = { ...data, withCredentials: true }

    return client.post(CHAT_MESSAGE_URL, custom)
  },
}
