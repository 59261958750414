import {
  ACTION_SET_ACCESS_TOKEN,
  ACTION_SEND_EVENT,
  ACTION_SET_LOADING,
  ACTION_SET_LOGGED_IN,
  ACTION_SET_PROFILE,
  UserAction,
  UserState,
  ACTION_SET_IDENTIFIER,
} from './types'

const initState: UserState = {
  loading: true,
  loggedInState: 'loading',
}

export default function reducer(state = initState, action: UserAction): UserState {
  switch (action.type) {
    case ACTION_SEND_EVENT:
      return {
        ...state,
        event: action.payload,
      }
    case ACTION_SET_PROFILE:
      return {
        ...state,
        profile: action.payload.profile,
        uId: action.payload.uId,
      }
    case ACTION_SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      }
    case ACTION_SET_IDENTIFIER:
      return {
        ...state,
        identifier: action.payload,
      }
    case ACTION_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case ACTION_SET_LOGGED_IN:
      return {
        ...state,
        loggedInState: action.payload ? 'yes' : 'no',
      }
    default:
      return state
  }
}
