const CalcOneRmList = (oneRmWeight: number) => {
  const maxRep = oneRmWeight
  const myArr = [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30]
  return myArr.map(w => {
    return {
      percentage: w,
      lift: (w / 100) * maxRep,
      reps: w === 100 ? 1 : Math.ceil(30 * (100 / w - 1)),
    }
  })
}

export default CalcOneRmList
