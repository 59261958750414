import InchConverter from 'components/common/InchConverter'
import PoundConverter from 'components/common/PoundConverter'

export interface Props {
  weight: number
  gender?: string
  waist: number
}

// Calculate Bodyfat
// USE AS A LAST RESORT! This uses a very inaccurate formula to attempt
//  to calculate your bodyfat percent. It is dependant on and very sensitive
//   to your waist size measurement as well as your weight, and tends to
//   under-estimate bf%. Formulas used are:

// Male: 100 x (-98.42 + 4.15 x waist - 0.082 x weight) / weight

// Female: 100 x (-76.76 + 4.15 x waist - 0.082 x weight) / weight

/**
 *
 * @param weight user body weight
 * @param gender user gender
 * @param waist user waist measure
 * @returns bodyfat %
 */

const CalcBodyfat = ({ weight, gender, waist }: Props) => {
  const isMale =
    (100 * (-98.42 + 4.15 * InchConverter(waist) - 0.082 * PoundConverter(weight))) /
    PoundConverter(weight)
  const isFemale =
    (100 * (-76.76 + 4.15 * InchConverter(waist) - 0.082 * PoundConverter(weight))) /
    PoundConverter(weight)

  const results = gender === 'male' ? isMale : isFemale
  return results
}

export default CalcBodyfat
