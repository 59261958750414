import { useSelector, useDispatch, batch } from 'react-redux'
import { fetchRestdaySuccess, fetchTrainingdaySuccess } from 'features/fatcalc/actions'
import { selectTdee, selectDiet, selectLiftingDays } from 'features/fatcalc/selectors'

/**
 * Function for determine amount of weekly amount of calories depening on weight regimen.
 * @returns weekly calories
 */

const CalcDietScheme = (): number => {
  const tdee = useSelector(selectTdee) as any
  const diet = useSelector(selectDiet) as any
  const liftingDays = useSelector(selectLiftingDays) as any

  const restDays = 7 - liftingDays
  // LeanMass
  const restdayKcalLM = restDays * (tdee - (tdee / 100) * 10)
  const liftingDaysKcalLM = liftingDays * tdee * 1.2
  const leanMass = liftingDaysKcalLM + restdayKcalLM

  // Weight Loss
  const restdayKcalWL = restDays * (tdee - (tdee / 100) * 20)
  const liftingDaysKcalWL = liftingDays * tdee
  const weightLoss = liftingDaysKcalWL + restdayKcalWL

  // agressive WL
  const restdayKcalagWL = restDays * (tdee - (tdee / 100) * 30)
  const liftingDaysKcalagWL = liftingDays * (tdee - (tdee / 100) * 10)
  const agWeightLoss = restdayKcalagWL + liftingDaysKcalagWL

  // agressive WL
  const restdayKcalagLM = restDays * (tdee * 1.1)
  const liftingDaysKcalagLM = liftingDays * tdee * 1.2
  const agLeanMass = restdayKcalagLM + liftingDaysKcalagLM

  const dispatch = useDispatch()

  if (diet) {
    switch (diet) {
      case 'leanMass':
        batch(() => {
          dispatch(fetchRestdaySuccess(restdayKcalLM))
          dispatch(fetchTrainingdaySuccess(liftingDaysKcalLM))
        })

        return Number(leanMass.toFixed(0))
      case 'weightloss':
        batch(() => {
          dispatch(fetchRestdaySuccess(restdayKcalWL))
          dispatch(fetchTrainingdaySuccess(liftingDaysKcalWL))
        })
        return Number(weightLoss.toFixed(0))
      case 'agweightloss':
        batch(() => {
          dispatch(fetchRestdaySuccess(restdayKcalagWL))
          dispatch(fetchTrainingdaySuccess(liftingDaysKcalagWL))
        })
        return Number(agWeightLoss.toFixed(0))
      case 'agleanMass':
        batch(() => {
          dispatch(fetchRestdaySuccess(restdayKcalagLM))
          dispatch(fetchTrainingdaySuccess(liftingDaysKcalagLM))
        })
        return Number(agLeanMass.toFixed(0))

      default:
        batch(() => {
          dispatch(fetchRestdaySuccess(restdayKcalLM))
          dispatch(fetchTrainingdaySuccess(liftingDaysKcalLM))
        })
        return leanMass
    }
  }

  return diet
}

export default CalcDietScheme
