import React from 'react'
import './FrontTemplate.scss'

interface Props {
  extra: React.ReactElement
  children?: React.ReactNode
}

const FrontTemplate: React.FC<Props> = ({ extra, children }) => {
  return (
    <div className="front-template-container">
      <div className="ft-col-6 ft-left">{extra}</div>
      <div className="ft-col-6 ft-right">{children}</div>
    </div>
  )
}
export default FrontTemplate
