import { createSelector } from 'reselect'
import { ApplicationState } from 'features/types'

const selectLocalState = (state: ApplicationState) => state.fatcalc

export const selectTdee = createSelector(selectLocalState, (state) => state.tdee)

export const selectWeight = createSelector(selectLocalState, (state) => state.weight)

export const selectDiet = createSelector(selectLocalState, (state) => state.diet)

export const selectLiftingDays = createSelector(selectLocalState, (state) => state.liftDays)

export const selectWeeklyTee = createSelector(selectLocalState, (state) => state.wkTee)

export const selectTrainingDays = createSelector(selectLocalState, (state) => state.trainingday)

export const selectRestDays = createSelector(selectLocalState, (state) => state.restday)
