import React from 'react'
import { Link } from 'react-router-dom'
import arrowIcon from 'assets/arrowRight.svg'

interface FooterLinkProps {
  path: string
  text: string
  homeIndicator: boolean
}
/**
 * Page navigation footer
 * @param path the navigation path
 * @param text element text
 * @param homeIndicator the conditional bool for deciding which of the two views to render
 *
 * @returns a footer JSX element
 */
const FooterLink: React.FC<FooterLinkProps> = ({ path, text, homeIndicator }) => {
  return homeIndicator ? (
    <Link to={path}>
      <div className="fc-one-rm-wrapper-bottom">
        <img className="fc-arrow fc-arrow-left" alt="arrow icon" src={arrowIcon} />
        <span className="fc-page-move-text">{text}</span>{' '}
      </div>
    </Link>
  ) : (
    <Link to={path}>
      <div className="fc-lp-wrapper-bottom">
        <span className="fc-page-move-text">{text}</span>{' '}
        <img className="fc-arrow" alt="right arrow" src={arrowIcon} />
      </div>
    </Link>
  )
}
export default FooterLink
