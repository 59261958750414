import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch, batch, connect } from 'react-redux'
import CalcDietScheme from 'components/common/CalcDietScheme'
import CalcKcalDiff from 'components/common/CalcKcaldiff'
import CalcKcalPerDay from 'components/common/CalcKcalPerDay'
import CalcKcalWchange from 'components/common/CalcKcalWchange'
import CalcWkTee from 'components/common/CalcWkTEE'
import Box from 'components/common/Layout/Box/Box'
import FooterLink from 'components/common/Layout/FooterLink'
import FrontTemplate from 'components/common/template/FrontTemplate'
import Macro, { CalcMacros } from 'components/form/MacroForm/Macro'
import {
  fetchTrainingdayKcalSuccess,
  fetchTeeSuccess,
  fetchRestdayKcalSuccess,
} from 'features/fatcalc/actions'
import { selectWeight, selectTdee } from 'features/fatcalc/selectors'
import { Nutrients, FcAppState } from 'features/fatcalc/types'
import './MacroPage.scss'

const MacroPage: React.FC = () => {
  const tdee = useSelector(selectTdee) as number
  const weight = useSelector(selectWeight) as number
  const tee: number = CalcWkTee({ tdee })
  const weekChange = CalcKcalDiff()
  const [restDayMacros, setRestDayMacros] = useState()
  const [trainDayMacros, setTrainDayMacros] = useState()
  const [stateValue, setStateValue] = useState<CalcMacros>({
    fatCarbSplit: '',
    proteinSplit: 0,
    validated: false,
  })

  const ad = Number(CalcKcalPerDay('training'))
  const da = Number(CalcKcalPerDay('rest'))

  const getMacros = (
    protein: number,
    trainingDay: number,
    restDay: number,
    split: any,
    weight: number
  ) => {
    const proteins = protein * weight
    const restDayKcalPostProtein = restDay - proteins * 4
    const trainDayKcalPostProtein = trainingDay - proteins * 4
    const carbsRestday = (restDayKcalPostProtein * split[0].carb) / 4
    const fatRestday = (restDayKcalPostProtein * split[0].fat) / 9

    const carbsTrainingday = (trainDayKcalPostProtein * split[1].carb) / 4
    const fatTrainingday = (trainDayKcalPostProtein * split[1].fat) / 9

    const rest: Nutrients[] = [
      {
        nutrient: 'Protein',
        grams: proteins,
        kcal: proteins * 4,
        percent: ((proteins * 4) / (restDayKcalPostProtein + proteins * 4)) * 100,
      },
      {
        nutrient: 'Carbohydrate',
        grams: carbsRestday,
        kcal: carbsRestday * 4,
        percent: ((carbsRestday * 4) / (restDayKcalPostProtein + proteins * 4)) * 100,
      },
      {
        nutrient: 'Fat',
        grams: fatRestday,
        kcal: fatRestday * 9,
        percent: ((fatRestday * 9) / (restDayKcalPostProtein + proteins * 4)) * 100,
      },
    ]
    const train: Nutrients[] = [
      {
        nutrient: 'Protein',
        grams: proteins,
        kcal: proteins * 4,
        percent: ((proteins * 4) / (trainDayKcalPostProtein + proteins * 4)) * 100,
      },
      {
        nutrient: 'Carbohydrate',
        grams: carbsTrainingday,
        kcal: carbsTrainingday * 4,
        percent: ((carbsTrainingday * 4) / (trainDayKcalPostProtein + proteins * 4)) * 100,
      },
      {
        nutrient: 'Fat',
        grams: fatTrainingday,
        kcal: fatTrainingday * 9,
        percent: ((fatTrainingday * 9) / (trainDayKcalPostProtein + proteins * 4)) * 100,
      },
    ]
    // @ts-ignore
    setRestDayMacros(rest)
    // @ts-ignore
    setTrainDayMacros(train)
  }

  const getTable = (resultList?: Nutrients[]) => {
    return (
      <table className="lp-table">
        <thead>
          <tr>
            <th>Nutrient</th>
            <th>Grams</th>
            <th>Kcal</th>
            <th>%</th>
          </tr>
        </thead>

        <tbody>
          {resultList
            ? resultList.map((item: Nutrients, index: any) => (
                <tr key={index}>
                  <td>{item.nutrient}</td>
                  <td>{item.grams.toFixed()}</td>
                  <td>{item.kcal.toFixed()}</td>
                  <td>{item.percent.toFixed(1)}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    )
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (stateValue.fatCarbSplit) {
      getMacros(stateValue.proteinSplit, ad, da, stateValue.fatCarbSplit, weight)
    }
    batch(() => {
      dispatch(fetchTeeSuccess(tee))
      dispatch(fetchTrainingdayKcalSuccess(ad))
      dispatch(fetchRestdayKcalSuccess(da))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tee, ad, da, stateValue])

  return (
    <FrontTemplate
      extra={
        <div className="left-content" style={{ marginTop: '40px' }}>
          <Macro onUpdate={(e) => setStateValue(e)} />
        </div>
      }
    >
      <div className="right-content shorten-right-page" style={{ marginTop: '21px' }}>
        <Box>
          <div className="fc-macro-flex-container">
            <div className="fc-macro-container-firstItem">
              {getTable(restDayMacros)}
              <div style={{ height: '100px' }} />
            </div>

            <div className="fc-macro-container-secondItem">
              <div className="fc-macro-container-rightcol">
                <h3>Resting Day</h3>
                <div className="mp-box-kcal">{da && stateValue.validated ? da : 0} Kcal</div>
              </div>
            </div>
          </div>
        </Box>

        <Box>
          <div className="fc-macro-flex-container">
            <div className="fc-macro-container-firstItem">
              {getTable(trainDayMacros)}
              <div style={{ height: '100px' }} />
            </div>

            <div className="fc-macro-container-secondItem">
              <div className="fc-macro-container-rightcol">
                <h3>Training Day</h3>
                <div className="mp-box-kcal">{ad > 0 && stateValue.validated ? ad : 0} Kcal</div>
              </div>
            </div>
          </div>
        </Box>

        <div className="fc-macro-flex-container test-class">
          <div className="fc-macro-footer-firstItem">
            <p>
              Weekly calories(TEE): <b>{tee.toFixed() && tee.toFixed()}</b>
            </p>
            <p>
              Daily calories(TDEE): <b>{tdee.toFixed() && tdee.toFixed()}</b>{' '}
            </p>
          </div>
          <div className="fix-lineheight">
            <p>
              Weekly total calories: <b>{tdee && CalcDietScheme()}</b>
            </p>
          </div>
          <div className="fix-lineheight">
            <p>
              Weekly calories Adjustment: <b>{tdee && weekChange.toFixed(0)}</b>
            </p>
          </div>
          <div className="fix-lineheight">
            <p>
              {' '}
              Weekly weight change: <b>{tdee && CalcKcalWchange(weekChange)}</b>{' '}
            </p>
          </div>
        </div>
        <FooterLink path="/" text="HOME" homeIndicator />
      </div>
    </FrontTemplate>
  )
}

const mapStateToProps = (state: FcAppState) => ({
  restDaySpecKcal: state.restDaySpecKcal,
  trainingDaySpecKcal: state.trainingDaySpecKcal,
})
export default connect(mapStateToProps)(MacroPage)
