import React from 'react'
import ErrorIcon from 'assets/error-icon.svg'
import infoIcon from 'assets/info-icon.svg'
import InputField from 'components/UX-lib/InputField/InputField'
import FormField from '../FormField/FormField'

interface FormInputFieldProps {
  id: string
  msg?: string
  label?: string
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  type?: 'text' | 'password' | 'email' | 'number'
  minLength?: number
  maxLength?: number
  min?: number
  max?: number
  pattern?: string
  errorMessage?: string
  value?: string
  ref?: any
}

const FormInputField: React.FC<FormInputFieldProps> = ({
  msg,
  id,
  label,
  required,
  onChange,
  onBlur,
  type,
  minLength,
  maxLength = 100,
  min,
  max,
  pattern,
  errorMessage,
  value,
}) => {
  return (
    <FormField
      fieldHelper={{
        msg,
        icon: infoIcon,
      }}
      shouldValidate
      valid={!errorMessage}
      validation={{
        icon: ErrorIcon,
        msg: errorMessage,
        type: 'error',
      }}
    >
      <InputField
        id={id}
        type={type}
        label={label}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        pattern={pattern}
        value={value}
      />
    </FormField>
  )
}

export default FormInputField
