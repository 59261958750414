import React from 'react'
import './FormField.css'
import classNames from 'classnames'
import InlineText from '../InlineText/InlineText'

// interface FieldHelperProps {
//   prefix: string
//   msg: string | undefined
//   linkText: string
//   link: string
//   icon: any
//   id: string
// }
interface ValidationProps {
  prefix?: string
  icon?: any
  type?: any
  msg?: any
  linkText?: string
  link?: string
  id?: string
}
interface FormFieldProps {
  children: any
  validation: ValidationProps
  fieldHelper: {
    prefix?: string
    msg?: any
    linkText?: string
    link?: string
    icon?: any
    id?: string
  }
  shouldValidate: boolean
  valid: boolean
  className?: string
  prefix?: string
}

const FormField: React.FC<FormFieldProps> = ({
  validation,
  fieldHelper,
  shouldValidate,
  valid,
  className,
  prefix,
  children,
  ...other
}) => {
  const classes = classNames(
    `${prefix}form-field`,
    !valid && shouldValidate && validation ? validation : '',
    className
  )
  let message = null
  let describedById = fieldHelper.id ? fieldHelper.id : null
  if (shouldValidate && !valid && validation.id) {
    describedById = validation.id ? validation.id : null
  }

  if (!valid && shouldValidate && validation.msg) {
    message = (
      <InlineText
        prefix={prefix}
        icon={validation.icon}
        type={validation.type}
        msg={validation.msg}
        linkText={validation.linkText}
        link={validation.link}
        id={validation.id}
        {...validation}
      />
    )
  } else if (fieldHelper.msg) {
    message = (
      <InlineText
        prefix={prefix}
        msg={fieldHelper.msg}
        linkText={fieldHelper.linkText}
        link={fieldHelper.link}
        icon={fieldHelper.icon}
        id={fieldHelper.id}
        {...fieldHelper}
      />
    )
  }

  return (
    <div className={classes} {...other}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          describedById,
        })
      })}
      {message}
    </div>
  )
}

export default FormField
