import {
  ACTION_SET_ACCESS_TOKEN,
  ACTION_SET_PROFILE,
  ACTION_SET_LOGGED_IN,
  SetAccessTokenAction,
  SetProfileAction,
  SetLoggedInAction,
  Contract,
  ACTION_SEND_EVENT,
  SendEventAction,
  UserEvent,
  SetLoadingAction,
  ACTION_SET_LOADING,
  SetIdentifierAction,
  ACTION_SET_IDENTIFIER,
} from './types'

export function setLoggedIn(value: boolean): SetLoggedInAction {
  return {
    type: ACTION_SET_LOGGED_IN,
    payload: value,
  }
}
export function setProfile(profile: Contract.BaseProfile | any, uId: string): SetProfileAction {
  return {
    type: ACTION_SET_PROFILE,
    payload: { profile, uId },
  }
}

export function setIdentifier(identifier: string): SetIdentifierAction {
  return {
    type: ACTION_SET_IDENTIFIER,
    payload: identifier,
  }
}

export function setAccessToken(accessToken: string): SetAccessTokenAction {
  return {
    type: ACTION_SET_ACCESS_TOKEN,
    payload: accessToken,
  }
}
export function sendEvent(event: UserEvent): SendEventAction {
  console.log('send event', event)
  return {
    type: ACTION_SEND_EVENT,
    payload: event,
  }
}

export function setLoading(value: boolean): SetLoadingAction {
  return {
    type: ACTION_SET_LOADING,
    payload: value,
  }
}
