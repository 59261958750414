import React from 'react'
import LoadingOverlay from 'react-loading-overlay-ts'
import './index.scss'

interface ButtonProps {
  text: string
  validation?: boolean
  loading?: boolean
  disabled?: boolean
  type?: string
  onClick?: () => void
}

/**
 * Button layout component
 * @param text string
 * @param validation bool
 * @param loading bool
 * @param onClick takes a function
 * @param disabled bool
 * @param type type of input
 * @returns button element
 */

const Button: React.FC<ButtonProps> = React.memo(
  ({ text, validation, loading, onClick, disabled, type }) => {
    return (
      <>
        {type ? (
          <LoadingOverlay active={loading} spinner>
            <input
              value={text}
              className={validation ? 'btn btn-fatcalc' : 'btn btn-fatcalc fc-button-phase'}
              type="submit"
              disabled={disabled}
            />
          </LoadingOverlay>
        ) : (
          <LoadingOverlay active={loading} spinner>
            <div
              className={validation ? 'btn btn-fatcalc' : 'btn btn-fatcalc fc-button-phase'}
              onClick={disabled ? () => {} : onClick}
            >
              {text}
            </div>
          </LoadingOverlay>
        )}
      </>
    )
  }
)
export default Button
