import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import validator from 'validator'
import MatchFatCarbSplit from 'components/common/MatchFatCarbSplit'
import CheckboxGroup from 'components/UX-lib/CheckboxGroup/CheckboxGroup'
import FormInputField from 'components/UX-lib/FormInputField/FormInputField'
import RadiobuttonGroup from 'components/UX-lib/RadiobuttonGroup/RadiobuttonGroup'
import { fetchDietSuccess, fetchLiftDaysSuccess } from 'features/fatcalc/actions'
import './Macro.scss'

export type State = {
  liftingDays: string
  fatCarbSplit: string
  dietChoice: string
  proteinSplit: string
}

export type CalcMacros = {
  fatCarbSplit: any
  proteinSplit: number
  validated: boolean
}
type Props = {
  onUpdate: (number: CalcMacros) => void
}

const Macro: React.FC<Props> = ({ onUpdate }) => {
  const [diet, setDiet] = useState('')
  const [protein, setProtein] = useState('')
  const [validation, setValidation] = useState(false)
  const [trainingDaysError, setTrainingDaysError] = useState('')

  const [formState, setFormState] = useState<State>({
    liftingDays: '',
    fatCarbSplit: '',
    proteinSplit: '',
    dietChoice: '',
  })

  const { liftingDays } = formState
  const dispatch = useDispatch()

  useEffect(() => {
    const liftingDays = Number(formState.liftingDays)
    dispatch(fetchDietSuccess(diet))
    dispatch(fetchLiftDaysSuccess(liftingDays))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diet, liftingDays])

  useEffect(() => {
    if (
      trainingDaysError === '' &&
      formState.liftingDays &&
      formState.fatCarbSplit &&
      protein &&
      diet
    ) {
      setValidation(true)
    } else {
      setValidation(false)
    }
  }, [trainingDaysError, formState.liftingDays, formState.fatCarbSplit, protein, diet])

  const setMyDiet = (prop: string) => {
    setDiet(prop)
  }
  const setMyProtein = (prop: string) => {
    setProtein(prop)
  }

  const handleChange = (formValue: Partial<State>) => {
    setFormState((currentState) => {
      return { ...currentState, ...formValue }
    })
  }

  const handleSubmit = (data: State) => {
    const proteinSplit = Number(protein)
    if (validation) {
      const match = MatchFatCarbSplit(data.fatCarbSplit)

      if (diet) {
        handleChange({ dietChoice: diet })
      }
      if (protein) {
        handleChange({ proteinSplit: protein })
      }
      onUpdate({
        fatCarbSplit: match,
        proteinSplit,
        validated: true,
      })
    }
  }
  return (
    <>
      <div className="fc-macro-box">
        <div className="fc-macro-headliner">
          <h2 className="right-headliner">Whats your Goal?</h2>
        </div>

        <p style={{ marginTop: '1.75rem' }}>Chose your preferred dieting option</p>
        <div className="radio-button-group-wrapper">
          <RadiobuttonGroup onDiet={setMyDiet} />
        </div>
        <div className="fc-macro-headliner second">
          <h2 className="right-headliner">Whats your Macros?</h2>
        </div>
        <p>Chose your preferred amount of protein, fat and carbohydrates</p>

        <div className="fc-macro-checkbox">
          <CheckboxGroup onOption={setMyProtein} />
        </div>
        <select
          className="fc-lp-dd shorten65"
          id="gender"
          value={formState.fatCarbSplit}
          required
          onChange={(e) => handleChange({ fatCarbSplit: e.target.value })}
        >
          <option>Carb/Fat Split (rest day - training day)</option>
          <option value="First">50/50 - 75/25</option>
          <option value="Second">25/75 - 75/25</option>
          <option value="Third">20/80 - 80/20</option>
          <option value="Fourth">10/90 - 90/10</option>
        </select>

        <div className="fc-macro-headliner third">
          <h2 className="right-headliner">Whats your Schedule?</h2>
        </div>
        <div>
          <div className="fc-macro-flex-input">
            <span style={{ flex: '1', lineHeight: '75px', padding: '0', marginTop: '-20px' }}>
              Workouts per week
            </span>
            <div className="fc-macro-flex-input-item-2">
              <div className="shorten">
                <FormInputField
                  id="liftingDays"
                  value={formState.liftingDays}
                  type="number"
                  label="Days"
                  required
                  min={1}
                  max={7}
                  onChange={(e) => handleChange({ liftingDays: e.target.value })}
                  onBlur={(e) => {
                    if (!validator.matches(e.target.value, /^[1-9]$/)) {
                      setTrainingDaysError('Please enter a valid number')
                    } else {
                      setTrainingDaysError('')
                    }
                  }}
                  errorMessage={trainingDaysError}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={validation ? 'btn btn-fatcalc' : 'btn btn-fatcalc fc-button-phase'}
          onClick={() => handleSubmit(formState)}
        >
          Submit
        </div>
      </div>
    </>
  )
}
export default Macro
