import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { chatRooms } from 'data/chatRooms'
import { selectProfile } from 'features/user/selectors'
import { Routes } from 'routes/types'
import MessageInput from './Message/MessageInput'
import { MessageList } from './Message/MessageList'
import './index.scss'

const ChatRoom: React.FC = () => {
  const profile = useSelector(selectProfile)
  const params = useParams()

  const room = chatRooms.find((x) => x.id === params.id)

  if (!room) {
    // TODO: return 404
  }

  return (
    <>
      {room && profile ? (
        <div className="chat-page-wrapper">
          <h2>{room.title}</h2>
          <div>
            <Link to={Routes.ChatPage}>Back to all rooms</Link>
          </div>
          {/* <div onClick={() => sendMessage(message)}>send chat button</div> */}
          <div className="messages-container">
            <MessageList roomId={room.id} />
            <MessageInput roomId={room.id} />
          </div>
        </div>
      ) : (
        'No room selected'
      )}
    </>
  )
}

export default ChatRoom
