import React, { useState, ChangeEvent, useEffect } from 'react'
import '../Checkbox/CheckboxComp.scss'

export interface CheckProps {
  onOption: (selected: string) => void
}

const Checkbox = (props: {
  label: string
  checked: boolean
  id: string
  name: string
  disabled: boolean
  onChange: (e: ChangeEvent<HTMLElement>) => void
}) => {
  return <input {...props} type="checkbox" className="ckbox" />
}

const CheckboxGroup: React.FC<CheckProps> = ({ onOption }) => {
  const [selected, setSelected] = useState('1')

  useEffect(() => {
    onOption(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const list = [
    {
      label: '1g/kg',
      id: '1',
      name: 'groupCheck1',
      disabled: false,
    },
    {
      label: '1.5g/kg',
      id: '1.5',
      name: 'groupCheck2',
      disabled: false,
    },
    {
      label: '2g/kg',
      id: '2',
      name: 'groupCheck3',
      disabled: false,
    },
    {
      label: '2.5g/kg',
      id: '2.5',
      name: 'groupCheck4',
      disabled: false,
    },
  ]

  return (
    <>
      <div className="cbg-flex">
        {list.map(item => (
          <>
            <div className="field-group round cbg-space">
              <Checkbox
                {...item}
                key={item.id}
                onChange={() => setSelected(item.id)}
                checked={selected === item.id}
              />
              <label className="ckbox-label" htmlFor={item.id}>
                {item.label}
              </label>
            </div>
          </>
        ))}
      </div>
    </>
  )
}

export default CheckboxGroup
