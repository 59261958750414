import React, { useState } from 'react'
import Confetti from 'react-confetti'
import gift from 'assets/gift.svg'
import './index.scss'

export default function BirthdayPage() {
  const [play, setPlay] = useState(false)

  const openPresent = () => {
    return (
      <div>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/hZDJjSHHGok?autoplay=1&enablejsapi=1"
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay"
          // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    )
  }

  return (
    <div className="wrapper">
      <h1 className="gradient-text">Happy birthday Astrid</h1>

      {!play ? (
        <>
          <p>
            <strong>Click Me</strong>
          </p>
          <img alt="gift" src={gift} className="bounce fa-gift" onClick={() => setPlay(true)} />
        </>
      ) : (
        <div style={{ marginTop: '40px' }}>
          <Confetti width={2000} height={800} className="wrapper" />
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/hZDJjSHHGok?autoplay=1&enablejsapi=1"
            title="YouTube video player"
            frameBorder="0"
            allow="autoplay"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  )
}
