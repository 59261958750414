import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from 'firebase/firestore'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}

let instance: any = null

export default function getFirebase() {
  if (instance) return instance
  instance = initializeApp(config)
  return instance
}

// export function firebaseAuthHandler() {
//   const profile = useSelector(selectProfile)
//   let auth
//   auth = getAuth(getFirebase())
//   onAuthStateChanged(auth, (user) => {
//     // Check for user status
//     console.log('user', user)

//     if(user && !profile){

//     }
//   })
//   return auth
// }
export function logout() {
  const auth = getAuth(getFirebase())
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
      console.log('logout err', error)
    })
}

// Init firestore db
export const database = getFirestore(getFirebase())

// Initialize Analytics

// TODOköras i FN

isSupported().then((result) => {
  if (result) {
    const analytics = getAnalytics(getFirebase())
    logEvent(analytics, 'notification_received')
  }
})

/**
 * Function snapshotting message collections in firestore db
 *
 * @param callback takes a callback function.
 * @param roomId string id.
 */
export function getMessages(roomId: string, callback: any) {
  return onSnapshot(
    query(collection(database, 'chat-rooms', roomId, 'messages'), orderBy('timestamp', 'asc')),
    (querySnapshot) => {
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      callback(messages)
    }
  )
}
