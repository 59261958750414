import React from 'react'
import classNames from 'classnames'
import './InlineText.scss'

interface Props {
  icon: string
  link?: string
  linkText: any
  msg: any
  prefix?: string
  className?: string
}

const InlineText: React.FC<Props> = ({
  className,
  prefix,
  icon,
  link,
  linkText,
  msg,
  ...other
}) => {
  const classes = classNames(`${prefix}inline-text`, className)
  return (
    <div className={classes} {...other} style={{ display: 'flex' }}>
      {icon && (
        <img
          className="error-icon"
          src={icon}
          alt="info-error-icon"
          prefix={prefix} /* size={'100'} */
        />
      )}
      <span className="error-text">
        {msg}{' '}
        {linkText && (
          <a href={link} prefix={prefix}>
            {linkText}
          </a>
        )}
      </span>
    </div>
  )
}

export default InlineText
