import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LoadingComponent from 'components/common/LoadingComponent'
import * as AppActions from 'features/app/actions'
import CONSTANTS from 'features/constants'
import * as MessageActions from 'features/message/actions'
import * as UserActions from 'features/user/actions'
import { useAuth, useSignupAccount } from 'features/user/hooks'
import {
  selectEvent,
  selectFacebookProvider,
  selectGoogleProvider,
  selectProfile,
} from 'features/user/selectors'
import { ErrorResponse, Contract, UserEventType } from 'features/user/types'
import { Routes } from 'routes/types'

import API from '../../../api'

/**
 * The loading intent governs what should load
 */
enum LoadingIntent {
  None,
  Signup,
  Login,
  Logout,
}

type LoginProps = {
  user: Contract.ExtendedProfile
  validation: boolean
}

/**
 * The Authentication hub transfers the authentication state (logged in, logged out) to redux for easy
 * global access using selectors.
 */
const AuthenticationHub: React.FC = () => {
  const event = useSelector(selectEvent)
  const [loading, setLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [error, setError] = useState<ErrorResponse>()
  const [user, setUser] = useState<Partial<Contract.BaseProfile>>({})
  const [loadingIntent, setLoadingIntent] = useState(LoadingIntent.None)
  const { getAuthCookies, isAuthenticated: validSession, userIdentifier } = useAuth()
  const {
    signupAccount,
    loading: signupLoading,
    error: mongoError,
    success: signupSuccess,
    user: mongodbUser,
  } = useSignupAccount()
  const googleProvider = useSelector(selectGoogleProvider)
  const facebookProvider = useSelector(selectFacebookProvider)
  const profile = useSelector(selectProfile)
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const isGoogle = googleProvider === 'google'
  const isFacebook = facebookProvider === 'facebook'

  useEffect(() => {
    dispatch(UserActions.setLoading(loading))
  }, [dispatch, loading])

  useEffect(() => {
    if (profile) {
      setUser({
        userName: profile.userName,
        email: profile.email,
      })
      dispatch(UserActions.setIdentifier(profile.email))
      setIsAuthenticated(true)
    }
  }, [profile])

  useEffect(() => {
    if (!loading) {
      if ((user && isAuthenticated) || validSession) {
        dispatch(UserActions.setLoggedIn(true))
        dispatch(UserActions.sendEvent({ type: UserEventType.GetProfile }))
      } else {
        dispatch(UserActions.setLoggedIn(false))
      }
    }
  }, [dispatch, user, isAuthenticated, loading, validSession])

  // DETTA GÖRS REDAN I PROVIDERLOGIN**** checka så denna funkar utan tusen anrop med regular inlogg
  // providerlogin, getAuthCookies, getProfile

  const getProfile = useCallback(async () => {
    // if ((user && user.email && isAuthenticated) || (validSession && userId)) {
    // && !profile?
    if (
      (userIdentifier && isAuthenticated && !isGoogle && !isFacebook && !profile) ||
      (userIdentifier && validSession && !isGoogle && !isFacebook && !profile)
    ) {
      try {
        const userProfile = await API.readUser(userIdentifier)

        dispatch(UserActions.setProfile((await userProfile).data, userIdentifier))
        return userProfile
      } catch (error) {
        dispatch(UserActions.sendEvent({ type: UserEventType.ForceLogout }))
      }
    }
  }, [dispatch, user, isAuthenticated, userIdentifier, validSession, isGoogle, isFacebook])

  const loginUser = useCallback(async (props: LoginProps) => {
    if (props.validation) {
      try {
        setLoading(true)
        setError(undefined)
        const response = await API.authUser(props.user)

        setUser({
          userName: response.data.userName,
          email: response.data.email,
        })
        dispatch(UserActions.setIdentifier(response.data.email))
        setIsAuthenticated(true)
      } catch (e: any) {
        if (!e.response) {
          setError({
            errorCode: 'SOMETHING_WENT_WRONG',
            errorType: 'ERROR-NO-RESPONSE',
            errorMessage: 'Something went wrong, please try again later',
          } as ErrorResponse)
        } else {
          setError(e.response.data as ErrorResponse)
        }
      } finally {
        setLoading(false)
        dispatch(AppActions.setLoading(false))
      }
    }
  }, [])

  const logoutUser = useCallback(async () => {
    if ((profile && isAuthenticated) || (userIdentifier && validSession)) {
      try {
        setLoading(true)
        const res = await axios.get(`${CONSTANTS.API_HOST}/logout-user`, { withCredentials: true })
        // clear state
        dispatch(UserActions.setProfile(undefined, ''))

        dispatch(UserActions.setIdentifier(''))
        setIsAuthenticated(false)

        return navigate(Routes.Home)
      } catch (e: any) {
        if (!e.response) {
          setError({
            errorCode: 'SOMETHING_WENT_WRONG',
            errorType: 'ERROR-NO-RESPONSE',
            errorMessage: 'Something went wrong, please try again later',
          } as ErrorResponse)
        } else {
          setError(e.response.data as ErrorResponse)
        }
      } finally {
        setLoading(false)
        dispatch(AppActions.setLoading(false))
      }
    }
  }, [dispatch, isAuthenticated, validSession, userIdentifier])

  const testingProviderSignup = useCallback(
    async (user: any) => {
      const data = {
        userName: (user && user.displayName) || undefined,
        email: (user && user.email) || undefined,
        provider:
          user.providerData[0].providerId == 'google.com'
            ? Contract.AuthProvider.Google
            : Contract.AuthProvider.Facebook,
        password: 'temp123',
      }
      await signupAccount(data, true)
        .then((response) => {
          console.log('res', response)
          // fix loading screen
          // dispatch(AppActions.setLoading(false))
          return navigate(Routes.Login)
        })
        .catch((error) => {
          dispatch(AppActions.setLoading(false))

          dispatch(
            MessageActions.sendError('Something went wrong, please try again later', {
              error: 'SOMETHING_WENT_WRONG',
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          if (mongoError) {
            dispatch(
              MessageActions.sendError(mongoError.errorMessage, {
                error: error.errorType,
                buttonText: 'Close',
                showCountdown: true,
                timeout: 10000,
              })
            )
          }
        })
        .finally(() => {
          dispatch(AppActions.setLoading(false))
        })
    },
    [profile, user, signupSuccess]
  )

  const providerHandler = useCallback(async (props: any) => {
    const { user } = props
    if (user && !profile) {
      try {
        const verifiedUser = user.email || user.displayName
        const { data } = await API.readUser(verifiedUser)
        // const { data } = await API.readUser(user.email)
        if (data) {
          dispatch(UserActions.setProfile(await data, data.email))

          return data
        }
      } catch (err) {
        // @ts-ignore
        console.log('catch', err.response)
        // @ts-ignore
        if (err.response.status === 404) {
          return await testingProviderSignup(user)
        }
        dispatch(
          MessageActions.sendError('Something went wrong, please try again later', {
            error: 'SOMETHING_WENT_WRONG',
            buttonText: 'Close',
            showCountdown: true,
            timeout: 10000,
          })
        )
      }
    }
  }, [])

  useEffect(() => {
    if (event && !loading) {
      switch (event.type) {
        case UserEventType.GetSession:
          getAuthCookies()
          break
        case UserEventType.Login:
          loginUser(event.data)
          break
        case UserEventType.Logout:
          logoutUser()
          break
        case UserEventType.GetProfile:
          getProfile()
          break
        case UserEventType.ProviderLogin:
          if (!userIdentifier && !profile) {
            providerHandler(event.data)
          }
          break
        case UserEventType.RefreshProfile:
          getProfile()
          break
        case UserEventType.ForceLogout:
          // Send a message that an error has occured and then logout.
          dispatch(
            MessageActions.sendError('An error has occurred. Try again later.', {
              error: 'UNKOWN-ERROR',
              buttonText: 'Close',
              showCountdown: true,
              timeout: 10000,
            })
          )
          dispatch(UserActions.sendEvent({ type: UserEventType.Logout }))
          break
        default: // ???
          // dispatch(Actions.acknowledgeEvent())
          break
      }
    }
  }, [event, dispatch, loginUser, getProfile, providerHandler])

  const render = useCallback(() => {
    switch (loadingIntent) {
      case LoadingIntent.Signup: // Fall-through
      case LoadingIntent.Login: // Fall-through
      case LoadingIntent.Logout:
        return <LoadingComponent />
      case LoadingIntent.None: // Fall-through
      default:
        return null
    }
  }, [loadingIntent])
  return render()
}

export default AuthenticationHub
