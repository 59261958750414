import React, { useState } from 'react'
import FatCalc from 'assets/ny_logo_reg_2.png'
import CalcOneRmList from 'components/common/CalcOneRmList'
import Box from 'components/common/Layout/Box/Box'
import FooterLink from 'components/common/Layout/FooterLink'
import FrontTemplate from 'components/common/template/FrontTemplate'
import OneRm, { LiftStats } from 'components/form/OneRmForm/OneRm'
import './OneRmPage.scss'

const OneRmPage: React.FC = () => {
  const [value, setValue] = useState<LiftStats>({
    lift: 0,
  })

  const resultList = CalcOneRmList(value.lift)
  return (
    <FrontTemplate
      extra={
        <div className="left-content">
          <h2>Welcome to FatCalcs 1 RM calc</h2>
          <p>
            Calculate your one-rep max (1RM) for any lift. Your one-rep max is the max weight you
            can lift for a single repetition for a given exercise.
          </p>
          <div className="fc-oneRm-flex-row">
            <p>
              {' '}
              1RM, or a repetition max, is a definition in strength training. This definition
              specifies the load required to enable only one repetition (rope) to pass in one
              exercise. You can make a rough estimate of 1RM for different exercises by counting the
              number of reps (reps) that can be performed at a lower load, and then multiply by a
              factor.
              <p>
                Start by specifing the weight you plan to lift and the number of repititions you can
                do.
              </p>
            </p>
          </div>
          <h3>Enter Weight</h3>
          <OneRm onUpdate={(e) => setValue(e)} />
        </div>
      }
    >
      <div className="right-content">
        <Box>
          <h2>Result List</h2>
          Your one rep max is <b>{value.lift.toFixed(0)} kg</b>
          <br />
          <div>
            <table className="lp-table">
              <thead>
                <tr>
                  <th>Percentage of 1RM</th>
                  <th>Lift Weight</th>
                  <th>Repetitions of 1RM</th>
                </tr>
              </thead>

              <tbody>
                {resultList
                  ? resultList.map((lift, index) => (
                      <tr key={index}>
                        <td>{lift.percentage}%</td>
                        <td>{lift.lift.toFixed(0)}kg</td>
                        <td>{value.lift ? lift.reps : null}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </Box>
        <FooterLink path="/" text="HOME" homeIndicator />
      </div>
    </FrontTemplate>
  )
}

export default OneRmPage
