import React from 'react'
import Search from 'components/common/Search'
import { useAuth } from 'features/user/hooks'
import { Contract } from 'features/user/types'
import LoginPage from 'pages/LoginPage'
import './index.scss'

interface MemberProps {
  profile: Partial<Contract.BaseProfile>
}

/**
 * @param profile logged in user profile.
 *
 * @returns MemberPage
 */

const MemberPage: React.FC<MemberProps> = ({ profile }) => {
  const { isLoggedIn } = useAuth()
  return (
    <>
      {isLoggedIn ? (
        <div>
          <div className="fatcalc-user-wrapper">
            <h2>
              <span className="text">Welcome to Memberpage</span> {profile.userName}
            </h2>
            <p>/Work in progress/</p>
            <div style={{ width: '400px' }}>
              <Search />
            </div>
          </div>
        </div>
      ) : (
        <LoginPage />
      )}
    </>
  )
}
export default MemberPage
