import { ACTION_SET_LOADING, AppState, AppAction } from './types'

const initState: AppState = {
  loading: false,
}

export default function reducer(state = initState, action: AppAction): AppState {
  switch (action.type) {
    case ACTION_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    default:
      return state
  }
}
