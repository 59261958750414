import InchConverter from 'components/common/InchConverter'
import PoundConverter from 'components/common/PoundConverter'

export interface BMRProps {
  weight: number
  height: number
  gender?: string
  age: number
}

// Harris Benedict
// Male: 66+(6.23 x weight in pounds)+(12.7 x height in inches)-(6.76 x age in years)

//   Female: 655+(4.35 x weight in pounds)+(4.7 x height in inches)-(4.7 x age in years)

/**
 * Function for calculating a users Basal metabolic rate
 * @param weight
 * @param height
 * @param gender
 * @param age
 * @returns user Basal metabolic rate in number
 */

const CalcBMR = ({ weight, height, gender, age }: BMRProps) => {
  const isMale = 66 + 6.23 * PoundConverter(weight) + 12.7 * InchConverter(height) - 6.76 * age
  const isFemale = 655 + 4.35 * PoundConverter(weight) + 4.7 * InchConverter(height) - 4.7 * age

  const results = gender === 'male' ? isMale : isFemale
  return results
}

export default CalcBMR
