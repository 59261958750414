export interface Props {
  tdee: number
}

const CalcWkTEE = ({ tdee }: Props) => {
  const results = tdee * 7

  return results
}

export default CalcWkTEE
