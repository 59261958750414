export interface Props {
  weight: number
  reps: number
}

const CalcOneRM = ({ weight, reps }: Props) => {
  const results = weight * (1 + reps / 30)
  return results
}

export default CalcOneRM
